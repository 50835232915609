import { useState } from "react";
import { faqs } from "../constants";
import { useEffect } from "react";

const useFaqs = () => {
  const [newFaqs, setNewFaqs] = useState(faqs);
  const [filteredFaqs, setFilteredFaqs] = useState(
    newFaqs.filter((data) => data.active === true)
  );
  const clickToggleFaq = (index) => {
    setNewFaqs((prev) => {
        return prev.map((data, i) => ({
            ...data,
            active: i === index ? true : false,
          }));
    })
  }

  const clickToggleQuestions = (index) => {
    setFilteredFaqs((prev) => {
      return prev.map((data, i) => ({
        ...data,
        desc: data.desc.map((answer, j) => ({
          ...answer,
          status: j === index ? true : false,
        })),
      }));
    });
  };
  

  useEffect(() => {
    setFilteredFaqs((prev) => {
        return prev = newFaqs.filter((data) => data.active === true)
    })
  }, [newFaqs])

  return {
    newFaqs,
    setNewFaqs,
    filteredFaqs,
    setFilteredFaqs,
    clickToggleFaq,
    clickToggleQuestions,
  };
};

export default useFaqs;
