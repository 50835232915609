import React from 'react'

const CardUrl = ({url}) => {
    const blog_url = 'https://listening.sydani.org';
    const checkUrl = (data) => {
        if(data && data.startsWith("https")){
            return data;
        }
         else {
            return blog_url+data
         }
    }
    const image_url = checkUrl(url);
  return (
    <img className="bottom-image" alt="bottom-img" src={image_url}/>

  )
}

export default CardUrl