import React from "react";
import "./BehaviouralLevel.css";
import Select from "react-select";
import {
  setLevelData,
  setSelectedBehaviour,
} from "../../../../redux/actions/global.action";
import useLevel from "../../hooks/useLevel";
import { behaviouralCategories, generalLevelOptions } from "../../constants";
const BehaviouralLevel = () => {
  const {
    levelData,
    handleSelectGeneralLevel,
    activeStates,
    handleChangeState,
    lgasInState,
    handleChangeLga,
    wardsInLga,
    dispatch,
    selectedProgramArea,
    selectedBehaviour,
  } = useLevel();

  return (
    <div className="location-root-container">
      <div className="inner-root-container">
        <div className="level-selections-wrapper">
          <div class="level-top-container">
            <div className="level-selection-container">
              <p className="select-label">Select</p>
              <Select
                className="select-dropdown"
                options={behaviouralCategories}
                isClearable={false}
                value={selectedBehaviour}
                onChange={(value) => dispatch(setSelectedBehaviour(value))}
              />
            </div>
            <div className="level-selection-container">
              <p className="select-label">Select</p>
              <Select
                className="select-dropdown"
                options={generalLevelOptions}
                isClearable={false}
                value={levelData?.generalAction}
                onChange={(value) => handleSelectGeneralLevel(value)}
              />
            </div>
            <div className="level-img-section">
              <div>{selectedProgramArea?.icon}</div>
              <div>
                <div style={{ fontWeight: 500, fontSize: 17, marginBottom: 8 }}>
                  {selectedProgramArea?.name} data
                </div>
                <div style={{ fontSize: 12 }}>
                  Get insights for behavioural performance data on{" "}
                  {selectedProgramArea?.name}
                </div>
              </div>
            </div>
          </div>
          <div class="level-bottom-container">
            {["state_summary", "view_lga", "view_ward"]?.includes(
              levelData?.generalAction?.value
            ) ? (
              <div className="level-selection-container">
                <p className="select-label">Select State</p>
                <Select
                  value={levelData?.selectedState}
                  className="select-dropdown"
                  options={activeStates}
                  isClearable={false}
                  onChange={(value) => handleChangeState(value)}
                />
              </div>
            ) : (
              <div />
            )}
            {["view_lga", "view_ward"]?.includes(
              levelData?.generalAction?.value
            ) ? (
              <div className="level-selection-container">
                <p className="select-label">Select LGA</p>
                <Select
                  className="select-dropdown"
                  options={lgasInState}
                  value={levelData?.selectedLga}
                  onChange={(value) => handleChangeLga(value)}
                />
              </div>
            ) : (
              <>
                <div />
                <div />
              </>
            )}
            {levelData?.generalAction?.value === "view_ward" ? (
              <div className="level-selection-container">
                <p className="select-label">Select Ward</p>
                <Select
                  className="select-dropdown"
                  options={wardsInLga}
                  value={levelData?.selectedward}
                  onChange={(value) =>
                    dispatch(
                      setLevelData({
                        ...levelData,
                        selectedWard: value,
                      })
                    )
                  }
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BehaviouralLevel;
