export const SET_SHOW_POPUP = "SET_SHOW_POPUP";
export const SET_POPUP_CHILDREN = "SET_POPUP_CHILDREN";
export const SET_CONFIRM_MODAL = "SET_CONFIRM_MODAL";
export const SET_SELECTED_PROGRAM_AREA = "SET_SELECTED_PROGRAM_AREA";
export const SET_SELECTED_LEVEL = "SET_SELECTED_LEVEL";
export const SET_SELECTED_STATES = "SET_SELECTED_STATES";
export const SET_SELECTED_LGS = "SET_SELECTED_LGS";
export const SET_SELECTED_FACILITIES = "SET_SELECTED_FACILITIES";
export const SET_PRIMARY_INDICATORS = "SET_PRIMARY_INDICATORS";
export const SET_SECONDARY_INDICATORS = "SET_SECONDARY_INDICATORS";
export const SET_COUNTRY_IN_FOCUS = "SET_COUNTRY_IN_FOCUS";
export const SET_STATE_IN_FOCUS = "SET_STATE_IN_FOCUS";
export const SET_LG_IN_FOCUS = "SET_LG_IN_FOCUS";
export const SET_INDICATORS = "SET_INDICATORS";
export const SET_STATES = "SET_STATES";
export const SET_LGAS_IN_STATE = "SET_LGAS_IN_STATE";
export const SET_WARDS_IN_LGA = "SET_WARDS_IN_LGA";
export const SET_CHART = "SET_CHART";
export const SET_CHART_2 = "SET_CHART_2";
export const SET_PERIOD = "SET_PERIOD";

export const SET_FIRST_COMPARABLE = "SET_FIRST_COMPARABLE";
export const SET_SECOND_COMPARABLE = "SET_SECOND_COMPARABLE";
export const SET_THIRD_COMPARABLE = "SET_THIRD_COMPARABLE";

export const SET_ACTIVE_STATES = "SET_ACTIVE_STATES";
export const SET_ACTIVE_LGAS_OF_FOCUSED_STATE =
  "SET_ACTIVE_LGAS_OF_FOCUSED_STATE";

export const SET_GETTING_CHART_DATA = "SET_GETTING_CHART_DATA";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_ELEMENTS = "SET_SELECTED_ELEMENTS";

export const SET_DATA_TYPE = "SET_DATA_TYPE";

export const SET_DATA_SOURCE = "SET_DATA_SOURCE";

export const SET_LEVEL_DATA = "SET_LEVEL_DATA";
export const SET_SELECTED_BEHAVIOUR = "SET_SELECTED_BEHAVIOUR";
export const SET_DATA_SOURCES = "SET_DATA_SOURCES";
export const SET_PROGRAM_AREAS = "SET_PROGRAM_AREAS";
export const SET_SELECTED_INDICATORS = "SET_SELECTED_INDICATORS";

export const SET_INDICATOR_COLORS = "SET_INDICATOR_COLORS";
