import React from "react";
import "./LandingBody.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import map from "./Map.svg";
import sydani from "../../../../assets/images/LIP/sydani-logo.svg";
import nphe from "../../../../assets/images/LIP/nphe.svg";
import Lottie from "react-lottie";
import animationData from "../../../../lotties/lip.json";

import { Link } from "react-router-dom";
import { getToken } from "../../../../utils/helpers";

const LandingBody = () => {
  const token = getToken();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div className="landing_container">
        <div className="landing">
          <div className="landing_top">
            <div className="landing_top_text">
              <h1 className="landing_text_header">
                Real-time hub for diverse <br /> vaccine-related data sources
              </h1>
              <p className="landing_text_paragraph">
                The ACSM Data Hub is a repository of real-time information,{" "}
                <br />
                encompassing diverse data sources related to vaccination efforts
              </p>
              {!token && (
                <Link to="/login" className="landing_text_link">
                  Login to get started <ArrowRightAltIcon />
                </Link>
              )}
            </div>
            <div className="landing_top_image">
              <Lottie className="top-image-view" options={defaultOptions} />
            </div>
          </div>
        </div>
        <div className="landing_bottom">
          <div className="landing_bottom_map">
            <img className="landing_map_img" src={map} alt="map" />
            <span className="landing_map_text">Join the Data Revolution!</span>
          </div>
          <div className="landing_bottom_text">
            Let us bridge the gap between service delivery and demand generation
            data
            <br />
            Powered by{" "}
            <Link className="sydani-link" to="https://sydani.org">
              sydani
            </Link>
          </div>
          <div className="landing_bottom_icons">
            <div className="nphe-img">
              <img className="bottom-icons" src={nphe} alt="nphe-logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingBody;
