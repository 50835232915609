import { Box, Button, Typography } from "@mui/material";
import React from "react";
import "./Modal.css";
import csvLogo from "../../../../../../assets/images/LIP/csv-logo.svg";
import xlxLogo from "../../../../../../assets/images/LIP/xsls-icon.svg";
import pdfLogo from "../../../../../../assets/images/LIP/pdf-icon.svg";

const Modal = () => {
  const imgBox = [
    {
      img: xlxLogo,
      alt: "xlx-Logo",
      desc: "Download Data with data elements (xlx)",
      active: true,
    },
    // {
    //   img: csvLogo,
    //   alt: "csv-Logo",
    //   desc: "Download data in CSV",
    //   active: false,
    // },
    {
      img: pdfLogo,
      alt: "pdf-Logo",
      desc: "Download data in PDF",
      active: false,
    },
  ];

  const style = {
    position: "absolute",
    top: "25%",
    left: "10%",
    // transform: "translate(-50%, -50%)",
    width: 350,
    height: 450,
    bgcolor: "background.paper",
    color: "#011B60",
    borderRadius: 2,
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };

  return (
    <>
      <Box className="modal-container">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Export Visualization Data
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Select your preferred format to view program data and export to device
        </Typography>
        <Box className="modal-content">
          {imgBox.map((data) => (
            <Box flex="1">
              <Box
                className={data.active ? "img-box img-box-active" : "img-box"}
              >
                <img className="summary-img" src={data.img} alt={data.alt} />
              </Box>
              <Typography mt={2} variant="subtitle2">
                {data.desc}
              </Typography>
            </Box>
          ))}
        </Box>
        <Button
          mt={4}
          variant="contained"
          onClick={() => console.log("Çlose")}
          className="summary-box-btn"
        >
          Complete Export
        </Button>
      </Box>
    </>
  );
};

export default Modal;
