import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataSelect from "../components/DataSelect";
import BehaviouralLevel from "../components/BehaviouralLevel";
import Period from "../components/Period";
import RepositoryLevel from "../components/RepositoryLevel";
import SelectProgramArea from "../components/SelectProgramArea";
import Summary from "../components/Summary";
import { steps } from "../constants";
import globalService from "../../../services/global.service";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";

const useProgramAreas = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!dataType.trim().length) {
      navigate("/dashboard");
    }
  }, [activeStep]);
  const [openCustomize, setOpenCustomize] = React.useState(false);
  const handleOpenCustomize = () => setOpenCustomize((prev) => true);
  const handleCloseCustomize = () => setOpenCustomize((prev) => false);

  const [openData, setOpenData] = React.useState(false);
  const handleOpenData = () => setOpenData((prev) => true);
  const handleCloseData = () => setOpenData((prev) => false);

  const {
    period,
    dataType,
    selectedElements,
    selectedCategories,
    selectedIndicators,
    levelData,
    selectedBehaviour,
    selectedProgramArea,
    dataSource,
    chart,
    indicatorColors,
  } = useSelector((state) => state.global);

  const [exportType, setExportType] = useState("excel");
  const [downloading, setDownloading] = useState(false);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleGoBack = () => {
    const newActiveStep = activeStep - 1;
    setActiveStep(newActiveStep);
  };

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const renderTab = (activeStep) => {
    switch (activeStep) {
      case 0:
        return <SelectProgramArea setActiveStep={setActiveStep} />;
      case 1:
        return <DataSelect />;
      case 2:
        return dataType === "REPOSITORY" ? (
          <RepositoryLevel />
        ) : (
          <BehaviouralLevel />
        );
      case 3:
        return <Period />;
      case 4:
        return <Summary />;
      default:
        return <SelectProgramArea />;
    }
  };

  const downloadBehaviouralData = () => {
    setDownloading(true);
    globalService
      .getFinalBehaviouralData({
        program_area: selectedProgramArea?.name,
        data_type: "behavioural",
        period: `${period?.startDate},${period?.endDate}`,
        data_source: selectedBehaviour?.value,
        ...(levelData?.selectedState && {
          state: levelData?.selectedState?.value,
        }),
        ...(levelData?.selectedLga && { lga: levelData?.selectedLga?.value }),
        ...(levelData?.selectedWard && {
          ward: levelData?.selectedWard?.value,
        }),
        excel: "True",
      })
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset=UTF-8";
          const fileExt = ".xlsx";
          const workbook = XLSX.utils.book_new();
          // const workSheets = [];
          data?.message?.forEach((data, i) => {
            // const ws = workbook.Sheets[workbook.SheetNames[0]];
            const ws = XLSX.utils.json_to_sheet(data?.data);
            // const wb = { Sheets: { data: ws }, SheetNames: [data?.title] };
            XLSX.utils.book_append_sheet(
              workbook,
              ws,
              data?.title?.substring(0, 30)
            );
          });

          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });

          const blobData = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            blobData,
            "acsm-data-" + new Date().toISOString().replace(/:/g, "-") + fileExt
          );
        }
      })
      .catch((error) => {
        if (error?.message) {
          toast.error(error?.mesage);
        } else if (error?._server_messages) {
          const messages = JSON.parse(error?._server_messages);
          if (Array?.isArray(messages) && messages?.length) {
            const errorMessage = JSON.parse(messages[0]);
            if (errorMessage?.message) {
              // toast.error(errorMessage?.message);
            }
          }
        }
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const downloadFinalData = () => {
    // if (!dataSource) {
    //   return toast.info("Please select vaccine type");
    // }
    // if (!selectedIndicators?.length) {
    //   return toast.info("Please select indicators to visualize");
    // }
    setDownloading(true);
    globalService
      .getFinalData2({
        program_area: selectedProgramArea?.name,
        data_source: dataSource,
        indicator_ids: String(
          selectedIndicators?.map((indicator) => indicator?.ind_id)
        ),
        period: `${period?.startDate},${period?.endDate}`,
        ...(levelData?.selectedState && {
          state: levelData?.selectedState?.value,
        }),
        ...(levelData?.selectedLga && { lga: levelData?.selectedLga?.value }),
        ...(levelData?.selectedWard && {
          ward: levelData?.selectedWard?.value,
        }),
        excel: "True",
      })
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          const resp = data?.message.map((key) => {
            const { title } = key;
            if (key?.disaggregated === 1) {
              let labelData = [];
              const dataValue = Object.entries(key?.data).map(
                ([name, value]) => {
                  const dataObj = { [`${title}`]: name };
                  const labeldata = value.map((data, index) => {
                    const labelOptions = {};
                    if (key?.disaggregated === 1 && key?.single_choice === 0) {
                      dataObj[`${data.option}`] = data?.percentage;
                      labelOptions[`name${index}`] = data?.option;
                      labelOptions.value = data?.percentage;
                    } else {
                      dataObj[`percentage`] =
                        key?.indicator_type === "Percentage"
                          ? data?.percentage
                          : data?.question === null
                          ? 0
                          : data?.question;
                    }
                    return labelOptions;
                  });
                  labelData = labeldata;
                  return dataObj;
                }
              );
              return { ...key, data: dataValue, labelData };
            } else {
              return key;
            }
          });
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset=UTF-8";
          const fileExt = ".xlsx";
          const workbook = XLSX.utils.book_new();
          // const workSheets = [];
          resp?.forEach((data, i) => {
            // const ws = workbook.Sheets[workbook.SheetNames[0]];
            const ws = XLSX.utils.json_to_sheet(data?.data);
            // const wb = { Sheets: { data: ws }, SheetNames: [data?.title] };
            const xlsTitle = data?.title?.substring(0, 25);
            const sheetName = xlsTitle.replace(/[:\/?*\[\]]/g, "_");
            XLSX.utils.book_append_sheet(workbook, ws, `${sheetName}${i + 1}`);
          });

          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });

          const blobData = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            blobData,
            "acsm-data-" + new Date().toISOString().replace(/:/g, "-") + fileExt
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const handleModals = {
    openCustomize,
    setOpenCustomize,
    handleCloseCustomize,
    handleOpenCustomize,
    openData,
    setOpenData,
    handleOpenData,
    handleCloseData,
  };

  return {
    activeStep,
    handleStep,
    completed,
    renderTab,
    handleNext,
    setActiveStep,
    handleGoBack,
    handleGoToDashboard,
    dataType,
    handleModals,
    handleOpenData,
    setExportType,
    exportType,
    downloadBehaviouralData,
    downloadFinalData,
    downloading,
  };
};

export default useProgramAreas;
