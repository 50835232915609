import React, { useRef, useState, useEffect } from "react";
import "./DataSelect.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setIndicators,
  setDataSource,
  setSelectedIndicators,
} from "../../../../redux/actions/global.action";
import globalService from "../../../../services/global.service";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";

import { ReactComponent as EmptyIndicatorIcon } from "../../../../assets/images/EmptyFileIndicator.svg";

const DataSelect = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const scrollableRef = useRef();
  const dispatch = useDispatch();
  const {
    selectedProgramArea,
    indicators,
    dataSource,
    dataSources,
    selectedIndicators,
  } = useSelector((state) => state.global);
  const [gettingIndicators, setGettingIndicators] = useState(false);

  const handleSelectDataSource = (source) => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    // }
    dispatch(setIndicators([]));
    if (!selectedProgramArea?.data_sources?.includes(source)) {
      return toast.info("Currently not available for selection");
    }

    dispatch(setDataSource(source));
    if (source !== dataSource) getIndicators(source);
  };

  const handleSelectIndicator = (indicator) => {
    if (
      selectedIndicators
        ?.map((selected) => selected?.ind_id)
        ?.includes(indicator?.ind_id)
    ) {
      dispatch(
        setSelectedIndicators(
          selectedIndicators?.filter(
            (selected) => selected?.ind_id !== indicator?.ind_id
          )
        )
      );
    } else {
      dispatch(setSelectedIndicators([...selectedIndicators, indicator]));
    }
  };

  const handleSelectAllIndicators = (indicator) => {
    if (
      selectedIndicators?.length &&
      selectedIndicators?.length === indicators?.length
    ) {
      dispatch(setSelectedIndicators([]));
    } else {
      dispatch(setSelectedIndicators(indicators));
    }
  };

  const getIndicators = (source) => {
    setGettingIndicators(true);
    globalService
      .getIndicators(selectedProgramArea?.name, source)
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(setIndicators(data?.message));
          dispatch(setSelectedIndicators([]));
        } else {
          dispatch(setIndicators([]));
          dispatch(setSelectedIndicators([]));
        }
      })
      .catch((error) => {
        dispatch(setIndicators([]));
        dispatch(setSelectedIndicators([]));
        console.log(error, "here error");
      })
      .finally(() => setGettingIndicators(false));
  };

  useEffect(() => {
    if (selectedProgramArea?.data_sources?.length && !dataSource) {
      dispatch(setDataSource(selectedProgramArea?.data_sources[0]));
      getIndicators(selectedProgramArea?.data_sources[0]);
    }
  }, [selectedProgramArea]);

  return (
    <div className="data-select-root">
      <div className="program-areas-left-side">
        <div className={"data-selected-program-area"} style={{ marginTop: 35 }}>
          {selectedProgramArea?.name}
        </div>

        <div className="data-select-container">
          {dataSources.map((source, index) => (
            <div
              onClick={() => handleSelectDataSource(source)}
              className={
                dataSource === source
                  ? "selected-data-source data-select"
                  : !selectedProgramArea?.data_sources?.includes(source)
                  ? "inactive-area data-select"
                  : "data-select"
              }
              key={index}
            >
              <p className="data-selected-text">{source}</p>
            </div>
          ))}
        </div>
      </div>

      <div ref={scrollableRef} className="program-area-right">
        <p className="program-area-right-text">
          Please select your preferred indicators below
        </p>

        <>
          <div className="indicators-container">
            {gettingIndicators ? (
              <div className="indicator-left">
                {Array(10)
                  ?.fill(null)
                  .map((item, i) => (
                    <Skeleton height={40} />
                  ))}
              </div>
            ) : indicators?.length ? (
              <div className="indicator-right">
                <div className="indicators_inner_container">
                  <div
                    onClick={() => handleSelectAllIndicators()}
                    className="select-all-indicator-item-container"
                  >
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={selectedIndicators?.length == indicators?.length}
                    />
                    <p className="indicator-text">Select All</p>
                  </div>
                  {indicators?.map((indicator, index) => (
                    <div
                      onClick={() => handleSelectIndicator(indicator)}
                      className="indicator-item-container"
                      key={index}
                    >
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={selectedIndicators
                          ?.map((selected) => selected?.ind_id)
                          ?.includes(indicator?.ind_id)}
                      />
                      <p className="indicator-text">{indicator?.title}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="empty-container">
                <EmptyIndicatorIcon style={{ width: 200, height: 200 }} />
                <p style={{ color: "#011B60", marginTop: 10 }}>
                  No data to show, make your preferred selection
                </p>
              </div>
            )}
            <div className="empty-container-right">
              <div className="program-area-img-section">
                <div>{selectedProgramArea?.icon}</div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default DataSelect;
