import React, { useEffect, useState } from "react";
import "./RepositoryLevel.css";
import Select from "react-select";
import { setLevelData } from "../../../../redux/actions/global.action";
import { ReactComponent as Map } from "../../../../assets/images/Map.svg";
import useLevel from "../../hooks/useLevel";
import { generalLevelOptions, roles } from "../../constants";

const RepositoryLevel = () => {
  const {
    levelData,
    handleSelectGeneralLevel,
    activeStates,
    handleChangeState,
    lgasInState,
    handleChangeLga,
    wardsInLga,
    dispatch,
    userPermission,
    userRoles,
  } = useLevel();
  const [summaryValue, setSummaryValue] = useState(levelData?.selectedSummary);
  const { stateAdmin, lgaAdmin } = roles;
  const levelOptions = generalLevelOptions.map((data) => {
    const clearable = true;
    if(userPermission === stateAdmin){
      generalLevelOptions[0].isDisabled = clearable;
    } else if(userPermission === lgaAdmin){
      generalLevelOptions[0].isDisabled = clearable
      generalLevelOptions[1].isDisabled = clearable
    }
    return data;
  });

  const stateOptions = activeStates.map((data) => {
    const clearable = true;
    if(userPermission === stateAdmin || userPermission === lgaAdmin){
      if(!(data.value === userRoles[1])){
        data.isDisabled = clearable;
        return data;
      } else {
        return data;
      }
    }
    return data;
  });

  const lgaOptions = lgasInState.map((data) => {
    const clearable = true;
    if(userPermission === lgaAdmin){
      if(!(data.value === userRoles[2])){
        data.isDisabled = clearable;
        return data;
      } else {
        return data;
      }
    }
    return data;
  });


  useEffect(() => {
    setSummaryValue(levelData?.selectedSummary)
  }, [levelData?.selectedSummary]);


  return (
    <div className="location-root-container">
      <div className="inner-root-container">
        <div className="level-selections-wrapper">
          <div className="level-top-container">
            <div className="level-selection-container">
              <p className="select-label">Select</p>
              <Select
                className="select-dropdown"
                options={levelOptions}
                isClearable={false}
                value={summaryValue}
                onChange={(value) => handleSelectGeneralLevel(value)}
              />
            </div>
            <div
              className="level-selection-map"
            >
              <Map className="level-map" />
              <div>
                <div className="level-map-header" >
                  Indicate your preferred action
                </div>
                <div className="level-map-body">
                  The ACSM Data Hub is a repository provides access to data from
                  nationwide, state, LGAs and local wards
                </div>
              </div>
            </div>
          </div>
          <div className="level-bottom-container">
            {["state_summary", "view_lga", "view_ward"]?.includes(
              levelData?.generalAction?.value
            || levelData?.generalAction?.default === "view_lga") ? (
              <div className="level-selection-container">
                <p className="select-label">Select State</p>
                <Select
                  value={levelData?.selectedState}
                  className="select-dropdown"
                  options={stateOptions}
                  isClearable={false}
                  onChange={(value) => handleChangeState(value)}
                />
              </div>
            ) : (
              <div />
            )}
            {["view_lga", "view_ward"]?.includes(
              levelData?.generalAction?.value
            ) ? (
              <div className="level-selection-container">
                <p className="select-label">Select LGA</p>
                <Select
                  className="select-dropdown"
                  options={lgaOptions}
                  value={levelData?.selectedLga}
                  onChange={(value) => handleChangeLga(value)}
                />
              </div>
            ) : (
              <>
                <div />
                <div />
              </>
            )}
            {levelData?.generalAction?.value === "view_ward" ? (
              <div className="level-selection-container">
                <p className="select-label">Select Ward</p>
                <Select
                  className="select-dropdown"
                  options={wardsInLga}
                  value={levelData?.selectedWard}
                  onChange={(value) =>
                    dispatch(
                      setLevelData({
                        ...levelData,
                        selectedWard: {label: value.label, value: value.value},
                      })
                    )
                  }
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepositoryLevel;
