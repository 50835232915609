import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Drawer, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { ProfileOptions } from "./components";
import "./Topbar.css";
import AcsmLogo from "../../../assets/images/LIP/Group 4008.png";
import { getToken, getUser, removeUserSession } from "../../../utils/helpers";
import authService from "../../../services/auth.service";
import { toast } from "react-toastify";
import SlidingBar from "./components/SlidingBar";

const TopBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const barRef = useRef();
  const token = getToken();
  const landingPages = [
    { label: "Home", path: "/", value: "home" },
    { label: "About", path: "/about", value: "about" },
    { label: "Resources", path: "#", value: "resources" },
    { label: "FAQs", path: "/faqs_support", value: "faqs_support" },
    { label: "Contact", path: "/contact", value: "contact" },
  ];

  const authPages = [
    { label: "Home", path: "/dashboard", value: "dashboard" },
    { label: "Resources", path: "#", value: "resources" },
    { label: "FAQs", path: "/faqs_support", value: "faqs_support" },
    { label: "Contact", path: "/contact", value: "contact" },
    // { label: "Profile", path: "/profile", value: "profile" },
  ];

  const pages = token ? authPages : landingPages;
  const location = useLocation();
  const navigate = useNavigate();
  const currentPage = location.pathname.split("/")[1];
  const user = getUser();
  const handleLogout = () => {
    if (user) {
      authService
        .logout(user?.email)
        .then(({ message }) => {
          removeUserSession();
          toast.success("Successfully logged out");
          navigate("/login");
        })
        .then(() => {
          removeUserSession();
          navigate("/login");
        });
    }
    removeUserSession();
    navigate("/login");
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (barRef.current && !barRef.current.contains(event.target)) {
        setIsMenuOpen((prev) => false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div ref={barRef} className="topBarWrapper">
      <Drawer
        sx={{ width: 230 }}
        className="top-bar-drawer"
        anchor="left"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen((prev) => false)}
      >
        <SlidingBar />
      </Drawer>
      <div style={{}} className="top-bar-left-side">
        <MenuIcon
          className="top-bar-menu"
          onClick={() => setIsMenuOpen((prev) => true)}
        />
        <img src={AcsmLogo} alt="home-display" onClick={() => navigate(token? `/dashboard`: `/`)} className="top-bar-img" />
      </div>
      <div className="top-bar-right">
        <ul style={{}} className="top-menu-list-wrapper">
          {pages.map((page, index) => (
            <li key={index}>
              <Link
                style={{
                  textDecoration: "none",
                  fontSize: 16,
                  color: page.value === "resources" ? "#cccccc": "#011B60",
                  fontWeight:
                    currentPage == page.path.split("/")[1] ? 600 : 400,
                }}
                to={page.path}
              >
                {page.label}
              </Link>
            </li>
          ))}
        </ul>

        <button
          onClick={() => {
            token ? handleLogout() : navigate("/login");
          }}
          className="top-login-btn"
        >
          {token ? "Logout" : "Login"}
        </button>
      </div>
    </div>
  );
};

export default TopBar;
