import { RadioButtonChecked } from "@mui/icons-material";
import { StepButton, Stepper, Step } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "./components/Summary/components/Modal";
import { steps } from "./constants";
import useProgramAreas from "./hooks/useProgramAreas";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./ProgramAreas.css";
import { useDispatch } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../redux/actions/global.action";
const ProgramAreas = () => {
  const [isFixed, setIsFixed] = useState(false);
  const dispatch = useDispatch();
  const {
    activeStep,
    handleStep,
    completed,
    renderTab,
    handleNext,
    handleGoBack,
    handleGoToDashboard,
    dataType,
    // handleModals,
    handleOpenData,
  } = useProgramAreas();
  const handleOpenCustomize = () => {
    dispatch(togglePopup(true));
    dispatch(setPopupChildren(<Modal />));
  };

  const divStyle = {
    position: isFixed ? 'fixed' : 'static',
    top: isFixed ? 0 : 'auto',
    zIndex: isFixed ? 100 : 0,
    width: '100%',
  };
  // const { openData } = handleModals;
  // console.log(openData);

  useEffect(() => {
    const handleScroll = () => {
      // You can adjust the scroll threshold based on your needs
      const scrollThreshold = 100;

      if (window.scrollY > scrollThreshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="page-wrapper-root">
      <Stepper style={divStyle} className="steps-container" nonLinear activeStep={activeStep}>
        {activeStep !== 0 &&
          steps.map((step, index) =>
            dataType === "PERFORMANCE_DATA" && index !== 1 ? (
              <Step
                style={{ color: activeStep === index ? "#FFC60C" : "#fff" }}
                key={step.id}
              >
                <StepButton
                  onClick={handleStep(index)}
                  completed={completed[index]}
                  icon={<RadioButtonChecked />}
                >
                  <div
                    className="program-stepper"
                    style={{
                      color: activeStep === index ? "#FFC60C" : "#fff",
                      ...(activeStep === index && { fontWeight: "600" }),
                    }}
                  >
                    {step.name}
                  </div>
                </StepButton>
              </Step>
            ) : (
              dataType === "REPOSITORY" && (
                <Step
                  style={{ color: activeStep === index ? "#FFC60C" : "#fff" }}
                  key={step.id}
                >
                  <StepButton
                    onClick={handleStep(index)}
                    completed={completed[index]}
                    icon={<RadioButtonChecked />}
                  >
                    <div
                      style={{
                        color: activeStep === index ? "#FFC60C" : "#fff",
                        ...(activeStep === index && { fontWeight: "600" }),
                      }}
                    >
                      {step.name}
                    </div>
                  </StepButton>
                </Step>
              )
            )
          )}
      </Stepper>

      <div className="contents-container">{renderTab(activeStep)}</div>

      <div className="page-footer">
        {activeStep !== 0 && (
          <button onClick={handleGoBack} className="go-back-btn">
            Back
          </button>
        )}
        {activeStep !== 4 && (
          <button onClick={handleNext} className="continue-btn">
            Continue{" "}
            {activeStep === 1
              ? " to Level"
              : activeStep === 2
              ? " to Time frame"
              : activeStep === 3
              ? " Visualization Type"
              : ""}
          </button>
        )}
      </div>

      <div className="custom-page-footer">
        {activeStep !== 0 && (
          <>
            <button onClick={handleGoBack} className="go-back-btn">
              Back
            </button>
          </>
        )}

        {activeStep === 0 && (
          <button onClick={handleGoToDashboard} className="go-dashboard-btn">
            <span>Back To Home</span>
          </button>
        )}
        {activeStep !== 0 && activeStep !== 4 && (
          <button onClick={handleNext} className="continue-btn">
            Continue{" "}
            {activeStep === 1
              ? " to Level"
              : activeStep === 2
              ? " to Time frame"
              : activeStep === 3
              ? " Visualization Type"
              : ""}
          </button>
        )}
        {activeStep === 4 && (
          <>
            <button onClick={handleOpenCustomize}>
              <CloudDownloadIcon className="header-icon" /> Export PDF/XLSX
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ProgramAreas;
