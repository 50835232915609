import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PopupModal from "../components/PopupModal";
import {
  setActiveStates,
  setDataSources,
  setIndicators,
  setProgramAreas,
  setSelectedLevel,
  setStateInFocus,
  setStates,
} from "../redux/actions/global.action";
import { setMyData } from "../redux/actions/user.action";
import globalService from "../services/global.service";
import { program_areas } from "../utils/constants";
import { getToken, getUser, removeUserSession } from "../utils/helpers";
import TopBar from "./components/TopBar";
import "./Layout.css";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const token = getToken();
  const location = useLocation();
  const page = location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const user = getUser();
  const { myData } = useSelector((state) => state.user);
  useEffect(() => {
    if (token) {
      // getIndicators();
      getStates();
      getActiveStates();
      getProgramAreas();
      getDataSources();
      dispatch(setMyData(user));
    } else {
      if (!["about", "resources", "faqs_support", "contact"].includes(page)) {
        removeUserSession();
        navigate("/login");
      }
    }
  }, [token]);

  const getStates = () => {
    globalService
      .getStates()
      .then((data) => {
        if (data.message) {
          dispatch(
            setStates(
              data.message?.map((state) => ({
                ...state,
                value: state.dh_id,
                label: state.dh_state,
              }))
            )
          );
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getProgramAreas = () => {
    globalService
      .getProgramAreas()
      .then((data) => {
        if (data.message && Array.isArray(data?.message)) {
          const combinedArray = program_areas?.map((item1) => {
            const matchingItem = data?.message?.find(
              (item2) => item2?.program_area === item1.name
            );
            return matchingItem
              ? { ...item1, ...matchingItem }
              : { ...item1, program_area: item1.name };
          });
          dispatch(setProgramAreas(combinedArray));
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getDataSources = () => {
    globalService
      .getDataSources()
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(setDataSources(data.message));
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getActiveStates = () => {
    globalService
      .getActiveStates()
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(
            setActiveStates(
              data.message?.map(({ state }) => ({
                value: state,
                label: state,
              }))
            )
          );

          if (
            user?.roles?.includes("VacNationalAdmin") ||
            user?.email == "national.vacinsight@sydani.org"
          ) {
            dispatch(setSelectedLevel("national"));
          }
          if (user?.roles?.includes("VacStateAdmin")) {
            const state = user?.roles.find((item) =>
              data.message?.some(({ state }) => state === item)
            );
            const stateObj = data.message?.filter(
              (obj) => obj.state == state
            )[0];

            dispatch(setSelectedLevel("state"));
            dispatch(
              setStateInFocus({
                ...stateObj,
                value: stateObj.state,
                label: stateObj.state,
              })
            );
          }
          if (user?.roles?.includes("VacLgaAdmin")) {
            const state = user?.roles.find((item) =>
              data.message?.some(({ state }) => state === item)
            );
            const stateObj = data.message?.filter(
              (obj) => obj.state == state
            )[0];
            dispatch(setSelectedLevel("lga"));
            dispatch(
              setStateInFocus({
                ...stateObj,
                value: stateObj.state,
                label: stateObj.state,
              })
            );
          }
        }
      })
      .then((err) => console.log("errrr", err));
  };

  return (
    <div className="layout-root">
      <TopBar />
      <PopupModal />
      <main className="layout-main-section">{children}</main>
      {page !== "vaccine_type" && (
        <div className="layout-footer-section">
          <div className="layout-footer-text">
            &#169; {new Date().getFullYear()} Copyright{" "}ACSM Data Hub. All Rights Reserved.
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
