import React, { useEffect, useState, forwardRef } from "react";
import "./BarChart.css";
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
} from "recharts";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import moment from "moment";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TuneIcon from "@mui/icons-material/Tune";
const BarChartb = forwardRef(({
  handleOpenData,
  handleOpenCustomize,
  setPageNav,
}, ref) => {
  const [isFixed, setIsFixed] = useState(false);
  const {
    gettingFinalData,
    chart2: chart,
    selectedIndicators,
    indicatorColors,
    levelData,
    period,
    selectedProgramArea,
    dataSource,
  } = useSelector((state) => state.global);
  const [chartData1, setChartData1] = useState([]);
  useEffect(() => {
    if (chart.length > 0) {

      const resp = chart.map((key) => {
        if (key.disaggregated === 1) {
          const labelData = [];
          const dataValue = Object.entries(key.data).map(([name, value]) => {
            const dataObj = { name };
            const labelOption = [];
            value.forEach((data, index) => {
              labelOption.push(data.option);
              dataObj[`${data.option}`] =
                key.indicator_type === "Percentage"
                  ? data?.percentage
                  : data?.question;
            });
            labelData.push(labelOption);

            return dataObj;
          });
          return { ...key, data: dataValue, labelData };
        } else {
          const labelData = [];
          const dataValue = key?.data.map((value, index) => {
            const dataObj = {name: value?.option};
            dataObj[`${value?.option}`] =
              key.indicator_type === "Percentage"
                ? value?.percentage
                : value?.question;
            labelData.push(value?.option);
            return dataObj
          });
          return {
            ...key,
            data: dataValue,
            labelData,
          };
        }
      });

      console.log(resp);
      setChartData1(resp);
    }
  }, [chart]);

  function convertDateFormat(originalDateString) {
    const originalDate = moment(originalDateString, "YYYY-MM-DD");

    const formattedDate = originalDate.format("MMMM D, YYYY");

    return formattedDate;
  }

  useEffect(() => {
    const getReturnedIndicators = () => {
      const allTypes = ["Pie", "Table", "Doughnut", "bar"];
      let resultType = [];
      const filteredIndicators = selectedIndicators
        .filter((data) => !data?.chart_type.includes("bar"))
      const getallStrings = filteredIndicators.map((data) => data.title);
      filteredIndicators.forEach((data) => {
        data.chart_type.forEach((d) => {
          resultType.push(d);
        });
      });
      const newResultType = [...new Set(resultType)];
      const filteredResultType = allTypes.filter(
        (data) => !newResultType.includes(data)
      );
      const faultingIndicators = {
        getallStrings: getallStrings.join(", "),
        getallTypes: filteredResultType.join(", "),
      };
      return {
        message: `The indicators: ${faultingIndicators.getallStrings}, cannot be viewed using this visualization type. Change the visualization type to Table or Bar chart to view all indicators.`,
        getallStrings,
      };
    };
    // const response = getReturnedIndicators();
    // if (response.getallStrings.length >= 1 && selectedIndicators > 0) {
    //   toast.info(response.message);
    // }
  }, [selectedIndicators]);

  
  const divStyle = {
    position: isFixed ? 'fixed' : 'static',
    top: isFixed ? '70px' : 'auto',
    zIndex: isFixed ? 100 : 0,
    backgroundColor: '#ffffff',
    width: '100%',
  };
  // const { openData } = handleModals;
  // console.log(openData);

  useEffect(() => {
    const handleScroll = () => {
      // You can adjust the scroll threshold based on your needs
      const scrollThreshold = 100;

      if (window.scrollY > scrollThreshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="barchart-root-container">
      <div className="chart-root-top" style={divStyle}>
        <p
          id="bold"
          onClick={() => setPageNav("default")}
          style={{ cursor: "pointer", color: "#011b60" }}
        >
          <ArrowCircleLeftIcon className="header-icon" />
          Change visualization type
        </p>
        <p>{selectedProgramArea.name}</p>|<p>{dataSource}</p>|
        <p>
          {convertDateFormat(period?.startDate)} -{" "}
          {convertDateFormat(period?.endDate)}
        </p>
        |
        {levelData?.generalAction?.value === "national_summary" ? (
          <p>Nigeria</p>
        ) : (
          <p>
            {levelData?.selectedWard && `${levelData?.selectedWard?.label}, `}
            {levelData?.selecteLga && `${levelData?.selecteLga?.label}, `}
            {levelData?.selectedState &&
              `${levelData?.selectedState?.label} State`}
          </p>
        )}
        <button onClick={handleOpenData}>
          <CloudDownloadIcon className="header-icon" /> Export PDF/XLSX
        </button>
        <button onClick={handleOpenCustomize}>
          <TuneIcon className="header-icon" /> Customize
        </button>
      </div>
      <div className="barchart-container" id="chart-area" ref={ref}>
        {gettingFinalData ? (
          selectedIndicators.map((chart, index) => (
            <div
              style={{
                width: "95%",
              }}
            >
              <Skeleton
                style={{
                  width: "100%",
                  margin: "-100px auto",
                }}
                height={500}
              />
            </div>
          ))
        ) : chartData1?.filter(
            (chart) => chart?.chart_type?.includes("bar") && chart
          )?.length ? (
          chartData1
            ?.filter((chart) => chart?.chart_type?.includes("bar") && chart)
            ?.map((item, index) => (
              <div className="barchart-container-box  html2pdf__page-break">
                <div className="graph-main-inner-container">
                  <div
                    style={{ fontWeight: 500, color: "#011b60", margin: 20, fontSize: 17 }}
                  >
                    {item?.title}
                  </div>

                  <ResponsiveContainer
                    debounce={300}
                    width={"100%"}
                    height={300}
                  >
                    <BarChart
                      margin={{ top: 0, right: 0, bottom: 5, left: 10 }}
                      data={item?.data}
                    >
                      <CartesianGrid strokeDasharray="0 1 0" />
                      <Tooltip />

                      <XAxis
                        axisLine={false}
                        tickLine={false}
                        dataKey={item?.data[0]?.option ? "option" : "name"}
                        fontSize={10}
                        height={20}
                      />
                      <YAxis
                        type="number"
                        domain={[0, 100]}
                        axisLine={false}
                        tickLine={false}
                        fontSize={10}
                        width={30}
                      />

                      {item?.disaggregated === 1 &&
                        item?.labelData[0]?.map((mapData, index) => (
                          <Bar
                            maxBarSize={20}
                            stackId="a"
                            // dataKey={item?.indicator_type === "Percentage" ? `percentage${index}` : `percentage${index}`}
                            dataKey={mapData}
                            fill={indicatorColors[index]}
                          />
                        ))}

                      {item?.disaggregated === 0 &&
                        item?.labelData?.map((mapData, index) => (
                          <Bar
                            maxBarSize={20}
                            stackId="a"
                            // dataKey={item?.indicator_type === "Percentage" ? `percentage${index}` : `percentage${index}`}
                            dataKey={mapData}
                            fill={indicatorColors[index]}
                          />
                        ))}

                      {/* <Bar dataKey="target" fill="#ffc60c" />
                      <Bar dataKey="pending" fill="#ffc4c4" />  */}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <p style={{fontSize: 17}}>
                  {dataSource === "Administrative Data" || dataSource === "Survey Data" ? item?.question : ''}
                </p>
              </div>
            ))
        ) : (
          <div style={{ color: "#011b60", marginTop: 100 }}>
            No record found
          </div>
        )}
      </div>
    </div>
  );
});

export default BarChartb;
