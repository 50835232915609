import React, { useEffect, useState, forwardRef } from "react";
import "./TableView.css";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import moment from "moment";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TuneIcon from "@mui/icons-material/Tune";
import { useVisualizeSummary } from "../../hooks/useVisualizeSummary";
const TableView = forwardRef(({
  handleOpenData,
  handleOpenCustomize,
  setPageNav,
}, ref) => {
  const [isFixed, setIsFixed] = useState(false);
  const {
    chart2: chart,
    selectedIndicators,
    levelData,
    period,
    dataSource,
    selectedProgramArea,
  } = useSelector((state) => state.global);
  const {gettingFinalData} = useVisualizeSummary()
  const [chartData1, setChartData1] = useState([]);

  function convertDateFormat(originalDateString) {
    const originalDate = moment(originalDateString, "YYYY-MM-DD");

    const formattedDate = originalDate.format("MMMM D, YYYY");

    return formattedDate;
  }

  // useEffect(() => {
  //   const getReturnedIndicators = () => {
  //     const allTypes = ["Pie", "Table", "Doughnut", "bar"];
  //     let resultType = [];
  //     const filteredIndicators = selectedIndicators
  //       .filter((data) => !data?.chart_type.includes("Table"))
  //       .map(({ title, chart_type }) => ({ title, chart_type }));
  //     const getallStrings = filteredIndicators.map((data) => data.title);
  //     filteredIndicators.forEach((data) => {
  //       data.chart_type.forEach((d) => {
  //         resultType.push(d);
  //       });
  //     });
  //     const newResultType = [...new Set(resultType)];
  //     const filteredResultType = allTypes.filter(
  //       (data) => !newResultType.includes(data)
  //     );
  //     const faultingIndicators = {
  //       getallStrings: getallStrings.join(", "),
  //       getallTypes: filteredResultType.join(", "),
  //     };
  //     return {
  //       message: `The indicators: ${faultingIndicators.getallStrings}, cannot be viewed using this visualization type. Change the visualization type to Table or Bar chart to view all indicators.`,
  //       getallStrings,
  //     };
  //   };
  //   const response = getReturnedIndicators();
  //   if (response.getallStrings.length >= 1 && selectedIndicators > 0) {
  //     toast.info(response.message);
  //   // }
  // }, [selectedIndicators]);

  useEffect(() => {
    if (chart.length > 0) {
      const resp = chart.map((key) => {
        if (key.disaggregated === 1) {
          let labelData = [];
          const dataValue = Object.entries(key.data).map(([name, value]) => {
            const dataObj = { name };
            const labeldata = value.map((data, index) => {
              dataObj[`percentage`] =
                key?.indicator_type === "Percentage"
                  ? data?.percentage
                  : data?.question === null
                  ? 0
                  : data?.question;
              const labelOptions = {};
              if (key?.disaggregated === 1 && key?.single_choice === 0) {
                dataObj[`${data.option}`] = data?.percentage;
                labelOptions[`name${index}`] = data?.option;
                labelOptions.value = data?.percentage;
              }
              return labelOptions;
            });
            labelData = labeldata;
            return dataObj;
          });
          return { ...key, data: dataValue, labelData };
        } else {
          return key;
        }
      });

      setChartData1(resp);
    }

    return () => {
      setChartData1(null);
    }
  }, [chart]);

  const divStyle = {
    position: isFixed ? 'fixed' : 'static',
    top: isFixed ? '70px' : 'auto',
    zIndex: isFixed ? 100 : 0,
    backgroundColor: '#ffffff',
    width: '100%',
  };

  useEffect(() => {
    const handleScroll = () => {
      // You can adjust the scroll threshold based on your needs
      const scrollThreshold = 100;

      if (window.scrollY > scrollThreshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="tableview-root-container">
      <div className="chart-root-top" style={divStyle}>
        <p
          id="bold"
          onClick={() => setPageNav("default")}
          style={{ cursor: "pointer", color: "#011b60" }}
        >
          <ArrowCircleLeftIcon className="header-icon" />
          Change visualization type
        </p>
        <p>{selectedProgramArea.name}</p>|<p>{dataSource}</p>|
        <p>
          {convertDateFormat(period?.startDate)} -{" "}
          {convertDateFormat(period?.endDate)}
        </p>
        |
        {levelData?.generalAction?.value === "national_summary" ? (
          <p>Nigeria</p>
        ) : (
          <p>
            {levelData?.selectedWard && `${levelData?.selectedWard?.label}, `}
            {levelData?.selecteLga && `${levelData?.selecteLga?.label}, `}
            {levelData?.selectedState &&
              `${levelData?.selectedState?.label} State`}
          </p>
        )}
        <button onClick={handleOpenData}>
          <CloudDownloadIcon className="header-icon" /> Export PDF/XLSX
        </button>
        <button onClick={handleOpenCustomize}>
          <TuneIcon className="header-icon" /> Customize
        </button>
      </div>
      <div className="tableview-left" id="chart-area" ref={ref}>
        {gettingFinalData ? (
          selectedIndicators.map((chart, index) => (
            <div
              style={{
                width: "95%",
              }}
            >
              <Skeleton
                style={{
                  width: "100%",
                  margin: "-100px auto",
                }}
                height={500}
              />
            </div>
          ))
        ) : chartData1?.filter(
            (chart) => chart?.chart_type?.includes("Table") && chartData1
          )?.length ? (
          chartData1
            ?.filter(
              (chart) => chart?.chart_type?.includes("Table") && chartData1
            )
            ?.map((item, index) => (
              <div className="graph-container-box html2pdf__page-break">
                <div className="graph-main-inner-container">
                  {["Administrative Data", "Survey Data"]?.includes(
                    dataSource
                  ) && (
                    <div
                      style={{
                        fontWeight: 500,
                        color: "#011b60",
                        marginBottom: 20,
                      }}
                    >
                      <p>
                        {item?.question}
                      </p>
                    </div>
                  )}
                  <table>
                    <thead>
                      <tr>
                        <th
                          style={{
                            width:
                              item?.disaggregated === 1 &&
                              item?.single_choice === 0
                                ? "50%"
                                : "70%",
                          }}
                        >
                          {item?.title}
                        </th>
                        {item?.disaggregated === 1 &&
                        item?.single_choice === 0 ? (
                          item?.labelData.map((data, index) => (
                            <th
                              style={{
                                fontSize: "15px",
                                fontWeight: 500,
                                width: "50px",
                              }}
                            >
                              {data[`name${index}`] ? data[`name${index}`] : item?.indicator_type === "Percentage"
                              ? "Percentage"
                              : "Value"}
                            </th>
                          ))
                        ) : (
                          <th>
                            {item?.indicator_type === "Percentage"
                              ? "Percentage"
                              : "Value"}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {item?.data?.map((data, index) => (
                        <>
                          <tr>
                            <td>{data?.name ? data?.name : data?.option}</td>
                            {item?.disaggregated === 1 &&
                            item?.single_choice === 0 ? (
                              item?.labelData.map((optData, index) => (
                                <td>{data[`${optData[`name${index}`]}`] || data?.percentage}</td>
                              ))
                            ) : (
                              <td>{data?.percentage}</td>
                            )}
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))
        ) : (
          <div style={{ color: "#011b60", marginTop: 100 }}>
            No record found
          </div>
        )}
      </div>
    </div>
  );
});

export default TableView;
