import {
  SET_CONFIRM_MODAL,
  SET_SELECTED_LEVEL,
  SET_POPUP_CHILDREN,
  SET_SELECTED_PROGRAM_AREA,
  SET_SHOW_POPUP,
  SET_SELECTED_STATES,
  SET_SELECTED_LGS,
  SET_PRIMARY_INDICATORS,
  SET_SELECTED_FACILITIES,
  SET_COUNTRY_IN_FOCUS,
  SET_STATE_IN_FOCUS,
  SET_LG_IN_FOCUS,
  SET_SECONDARY_INDICATORS,
  SET_INDICATORS,
  SET_STATES,
  SET_LGAS_IN_STATE,
  SET_WARDS_IN_LGA,
  SET_CHART,
  SET_CHART_2,
  SET_PERIOD,
  SET_FIRST_COMPARABLE,
  SET_SECOND_COMPARABLE,
  SET_THIRD_COMPARABLE,
  SET_ACTIVE_STATES,
  SET_ACTIVE_LGAS_OF_FOCUSED_STATE,
  SET_GETTING_CHART_DATA,
  SET_SELECTED_CATEGORY,
  SET_CATEGORIES,
  SET_SELECTED_ELEMENTS,
  SET_DATA_TYPE,
  SET_DATA_SOURCE,
  SET_LEVEL_DATA,
  SET_SELECTED_BEHAVIOUR,
  SET_DATA_SOURCES,
  SET_PROGRAM_AREAS,
  SET_SELECTED_INDICATORS,
  SET_INDICATOR_COLORS,
} from "../actionTypes/global.type";

export const togglePopup = (payload) => ({
  type: SET_SHOW_POPUP,
  payload,
});

export const setPopupChildren = (payload, onDismiss) => ({
  type: SET_POPUP_CHILDREN,
  payload,
  onDismiss: onDismiss || (() => {}),
});

export const setConfirmModal = (payload) => ({
  type: SET_CONFIRM_MODAL,
  payload,
});

export const setSelectedLevel = (payload) => ({
  type: SET_SELECTED_LEVEL,
  payload,
});

export const setSelectedStates = (payload) => ({
  type: SET_SELECTED_STATES,
  payload,
});

export const setSelectedLgas = (payload) => ({
  type: SET_SELECTED_LGS,
  payload,
});
export const setIndicators = (payload) => ({
  type: SET_INDICATORS,
  payload,
});
export const setPrimaryIndicators = (payload) => ({
  type: SET_PRIMARY_INDICATORS,
  payload,
});

export const setSecondaryIndicators = (payload) => ({
  type: SET_SECONDARY_INDICATORS,
  payload,
});

export const setSelectedProgramArea = (payload) => ({
  type: SET_SELECTED_PROGRAM_AREA,
  payload,
});

export const setSelectedFacilities = (payload) => ({
  type: SET_SELECTED_FACILITIES,
  payload,
});

export const setCountryInFocus = (payload) => ({
  type: SET_COUNTRY_IN_FOCUS,
  payload,
});

export const setStateInFocus = (payload) => ({
  type: SET_STATE_IN_FOCUS,
  payload,
});

export const setLgInFocus = (payload) => ({
  type: SET_LG_IN_FOCUS,
  payload,
});

export const setStates = (payload) => ({
  type: SET_STATES,
  payload,
});

export const setLgasInState = (payload) => ({
  type: SET_LGAS_IN_STATE,
  payload,
});

export const setWardsInLga = (payload) => ({
  type: SET_WARDS_IN_LGA,
  payload,
});

export const setChart = (payload) => ({
  type: SET_CHART,
  payload,
});

export const setChart2 = (payload) => ({
  type: SET_CHART_2,
  payload,
});

export const setPeriod = (payload) => ({
  type: SET_PERIOD,
  payload,
});

export const setFirstComparable = (payload) => ({
  type: SET_FIRST_COMPARABLE,
  payload,
});

export const setSecondComparable = (payload) => ({
  type: SET_SECOND_COMPARABLE,
  payload,
});

export const setThirdComparable = (payload) => ({
  type: SET_THIRD_COMPARABLE,
  payload,
});

export const setActiveStates = (payload) => ({
  type: SET_ACTIVE_STATES,
  payload,
});

export const setActiveLgasOfFocusedState = (payload) => ({
  type: SET_ACTIVE_LGAS_OF_FOCUSED_STATE,
  payload,
});

export const setGettingChartData = (payload) => ({
  type: SET_GETTING_CHART_DATA,
  payload,
});

export const setSelectedCategory = (payload) => ({
  type: SET_SELECTED_CATEGORY,
  payload,
});

export const setCategories = (payload) => ({
  type: SET_CATEGORIES,
  payload,
});

export const setSelectedElements = (payload) => ({
  type: SET_SELECTED_ELEMENTS,
  payload,
});
export const setDataType = (payload) => ({
  type: SET_DATA_TYPE,
  payload,
});
export const setDataSource = (payload) => ({
  type: SET_DATA_SOURCE,
  payload,
});

export const setLevelData = (payload) => ({
  type: SET_LEVEL_DATA,
  payload,
});

export const setSelectedBehaviour = (payload) => ({
  type: SET_SELECTED_BEHAVIOUR,
  payload,
});

export const setDataSources = (payload) => ({
  type: SET_DATA_SOURCES,
  payload,
});

export const setProgramAreas = (payload) => ({
  type: SET_PROGRAM_AREAS,
  payload,
});

export const setSelectedIndicators = (payload) => ({
  type: SET_SELECTED_INDICATORS,
  payload,
});

export const setIndicatorColors = (payload) => ({
  type: SET_INDICATOR_COLORS,
  payload,
});
