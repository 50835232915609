export const setUserLogin = () => {
  sessionStorage.setItem("isLipLoggedIn", true);
};

export const setUserLogOut = () => {
  sessionStorage.removeItem("isLipLoggedIn");
};

export const getUserLogin = (val) => {
  const isUserLoggedIn = sessionStorage.getItem("isLipLoggedIn");

  if (isUserLoggedIn) return isUserLoggedIn;
  else return null;
};

export function getToken() {
  const token = sessionStorage.getItem("lipToken");

  if (token) return token;
  else return null;
}

export function getUser() {
  const userStr = sessionStorage.getItem("vacInUser");
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const setToken = (token) => {
  sessionStorage.removeItem("lipToken");
  sessionStorage.setItem("lipToken", token);
};

export const setUserSession = (token, user) => {
  sessionStorage.removeItem("lipToken");
  sessionStorage.removeItem("vacInUser");
  sessionStorage.setItem("lipToken", token);
  sessionStorage.setItem("vacInUser", JSON.stringify(user));
};

export const setUser = (user) => {
  sessionStorage.removeItem("vacInUser");
  sessionStorage.setItem("vacInUser", JSON.stringify(user));
};

export const removeUserSession = () => {
  sessionStorage.removeItem("lipToken");
  sessionStorage.removeItem("vacInUser");
};

export const setDontShowIntro = (data) => {
  localStorage.setItem("dontShowVacIntro", data);
};

export const dontShowIntro = () => {
  const result = localStorage.getItem("dontShowVacIntro");

  if (result) return result;
  else return null;
};

export const groupedByLGA = (data) =>
  data.reduce((result, item) => {
    const lga = item.dh_lga;
    const existingItem = result.find((group) => group.dh_lga === lga);
    if (existingItem) {
      existingItem.items.push(item);
    } else {
      result.push({ dh_lga: lga, items: [item] });
    }
    return result;
  }, []);

export const groupedByState = (data) =>
  data.reduce((result, item) => {
    const state = item.dh_state;
    const existingItem = result.find((group) => group.dh_state === state);
    if (existingItem) {
      existingItem.items.push(item);
    } else {
      result.push({ dh_state: state, items: [item] });
    }
    return result;
  }, []);

export const groupedByFacility = (data) =>
  data.reduce((result, item) => {
    const facility = item.dh_facility;
    const existingItem = result.find((group) => group.dh_facility === facility);
    if (existingItem) {
      existingItem.items.push(item);
    } else {
      result.push({ dh_facility: facility, items: [item] });
    }
    return result;
  }, []);

export const getMonthsBetweenDates = (startDate, endDate) => {
  const months = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    months.push(`${year}-${month}`);

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
  // return [
  //   `${new Date(startDate).getFullYear()}-${String(
  //     startDate.getMonth() + 1
  //   ).padStart(2, "0")}`,
  //   `${new Date(endDate).getFullYear()}-${String(
  //     endDate.getMonth() + 1
  //   ).padStart(2, "0")}`,
  // ];
};
const monthYearConverter = (originalString) => {
  const parts = originalString.split("-");

  if (parts.length === 2) {
    const [month, year] = parts;

    const abbreviatedMonth = month.slice(0, 3);

    const resultString = `${abbreviatedMonth}-${year}`;

    return resultString;
  } else {
    return originalString;
  }
};
export const getRiStateChartData = (object) => {
  const months = object?.total_stock
    ? Object?.keys(object["total_stock"])
    : object?.total_coverage
    ? Object?.keys(object["total_coverage"])
    : object?.total_Penta_1
    ? Object?.keys(object["total_Penta_1"])
    : object?.total_stockout
    ? Object?.keys(object["total_stockout"])
    : object?.total_Penta_2
    ? Object?.keys(object["total_Penta_2"])
    : Object?.keys(object["total_wastage"]);

  const arrayData = months?.map((month) => ({
    period: monthYearConverter(month),
    ...(object?.total_stock && { total_stock: object["total_stock"][month] }),
    ...(object?.total_opening_balance && {
      total_opening_balance: object["total_opening_balance"][month],
    }),
    ...(object?.total_received && {
      total_received: object["total_received"][month],
    }),
    ...(object?.total_consumption && {
      total_consumption: object["total_consumption"][month],
    }),
    ...(object?.total_coverage && {
      total_coverage: object["total_coverage"][month],
    }),
    ...(object?.total_stockout && {
      total_stockout: object["total_stockout"][month],
    }),
    ...(object?.total_Penta_1 && {
      total_Penta_1: object["total_Penta_1"][month],
    }),
    ...(object?.total_Penta_2 && {
      total_Penta_2: object["total_Penta_2"][month],
    }),
    ...(object?.total_Penta_3 && {
      total_Penta_3: object["total_Penta_3"][month],
    }),
    ...(object?.total_wastage && {
      total_wastage: object["total_wastage"][month],
    }),
  }));

  return arrayData;
};

export const formatArrayToString = (arr) => {
  if (arr.length === 0) {
    return "";
  } else if (arr.length === 1) {
    return arr[0].toString();
  } else if (arr.length === 2) {
    return arr.join(" & ");
  } else {
    const lastItem = arr.pop();
    return arr.join(", ") + " & " + lastItem;
  }
};

export function hasMatchingValues(arr1, arr2) {
  console.log(arr1,arr2)
  const lowercasedArr1 = arr1?.map(value => value?.toLowerCase());
  const lowercasedArr2 = arr2?.map(value => value?.toLowerCase());
  return lowercasedArr1?.some(value => lowercasedArr2?.includes(value));
}

export const convertToNewDateFormat = (dateString) => {
  
const parts = dateString.split("-");
const year = parseInt(parts[0], 10);
const month = parseInt(parts[1], 10) - 1; // Months are zero-based (0 to 11)
const day = parseInt(parts[2], 10);

return new Date(year, month, day);
}
