import React, { useState } from "react";
import "./FaqAndHelp.css";
import useFaqs from "./Hooks/useFaqs";

const FaqAndHelp = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { newFaqs, filteredFaqs, clickToggleFaq, clickToggleQuestions } =
    useFaqs();
  return (
    <div className="faq-help-root-container">
      <div className="faq-inner-container flex-col-20">
        <div className="faq-top flex-row-30">
          <h1 className="faq-title">FAQs</h1>
        </div>
        <div className="faq-bottom flex-row-30">
          <div className="faq-bottom-content">
            <div className="faq-scrollable flex-col-30 flex-1">
              {newFaqs.map((data, index) => (
                <>
                  <div
                    key={index}
                    className="faq-bottom-left flex-1"
                    onClick={() => clickToggleFaq(index)}
                  >
                    <p className={`pointer ${data?.active && "active-text"}`}>
                      {data?.title}
                    </p>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="faq-bottom-right flex-col-20 flex-3">
            {filteredFaqs[0]?.desc.map((query, index) => (
              <>
                <div className="bottom-right-content flex-col-20" key={index}>
                  <div
                    className="faq-questions"
                    onClick={() => clickToggleQuestions(index)}
                  >
                    <div className="flex-row-10 flex-start pointer">
                      <span>{query?.status ? "-" : "+"}</span>
                      <p>{query?.question}</p>
                    </div>
                    {query?.status && (
                      <p className="faq-answers">{query?.answer}</p>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAndHelp;
