// import { Link } from "react-router-dom";
import "./Resources.css";
import useResources from "./Hooks/useResources";
import CardBottom from "./components/CardBottom";
import headerImage from "../../assets/images/LIP/people-office-during-work-day (1) copy.webp";
import { useState } from "react";
// import { Link as scrollLink, animateScroll as scroll } from "react-scroll";
import { Link, animateScroll as scroll } from "react-scroll";

const Resources = () => {
  const { getCategory, getDataByCategory, resourceLink, changeActiveLink } =
    useResources();
  const categories = getCategory();

  return (
    <div className="resources-root-container">
      <div className="resources-header">
        <div className="header-nav">
          {categories?.map((data) => (
            <Link
              to={data.name}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={0}
              className={resourceLink === data.name ? "bold-link" : ""}
              key={data.name}
              onClick={() => changeActiveLink(data.name)}
            >
              {data.name}
            </Link>
          ))}
        </div>
        <div className="header-body">
          <div className="header-body-left">
            <h1>ACSM Resources</h1>
            <p>
              The ACSM Data Hub is a centralized repository for all things ACSM.
              Whether you're looking for processed data, or simply want to learn
              more about ACSM, the Data Hub has something for everyone.
            </p>
          </div>
          <div className="header-body-right">
            <img src={headerImage} alt="header-display" />
          </div>
        </div>
      </div>
      <div className="resources-body">er
        {resourceLink === "Latest Articles" ? (
          <>
            <div className="body-content">
              {categories?.map((data) => (
                <div
                  className="customer-service"
                  key={data.name}
                  id={data.name}
                >
                  <div className="customer-top">
                    <p className="top-text">{data.name}</p>
                  </div>
                  <CardBottom
                    category={data.name}
                    getDataByCategory={getDataByCategory}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="body-content" id={resourceLink}>
              <div className="customer-service" key={resourceLink}>
                <div className="customer-top">
                  <p className="top-text">{resourceLink}</p>
                </div>
                <CardBottom
                  category={resourceLink}
                  getDataByCategory={getDataByCategory}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="resources-footer">
        <div className="footer-left">
          <h1>Get our resources in your inbox</h1>
          <p>
            All the tips, stories, and resources you could ever need or want in
            your inbox
          </p>
        </div>
        <div className="footer-right">
          <div className="group-input">
            <input type="text" placeholder="Email Address*" />
            <button>Subscribe</button>
          </div>
          <p>
            Your privacy matters! Help Scout only uses this info to send content
            and updates. You may unsubscribe anytime. View our{" "}
            <Link>privacy policy</Link> for more.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Resources;
