import React from "react";
import TopBar from "../../layout/components/TopBar";
import LandingBody from "./components/LandingBody/LandingBody";

const Home = () => {
  return (
    <>
      <TopBar />
      <LandingBody />
    </>
  );
};

export default Home;
