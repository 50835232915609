import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStates,
  setLevelData,
  setLgasInState,
  setWardsInLga,
} from "../../../redux/actions/global.action";
import globalService from "../../../services/global.service";
import { roles } from "../constants";

const useLevel = () => {
  const { myData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    levelData,
    activeStates,
    lgasInState,
    wardsInLga,
    selectedProgramArea,
    selectedBehaviour,
  } = useSelector((state) => state.global);
  const userRoles = myData.roles;
  const [userPermission, setUserPermission] = useState("");

  const { nationalAdmin, stateAdmin, lgaAdmin } = roles;
  const { roles: returnedRoles } = myData;

  useEffect(() => {
    if (returnedRoles.includes(lgaAdmin)) {
      getActiveLgaInState(returnedRoles[1]);
      getActiveWardsInLga(returnedRoles[1], returnedRoles[2]);
      const valueData = {
        label: "View LGA",
        value: "view_lga",
      };
      setUserPermission(lgaAdmin);
      dispatch(
        setLevelData({
          ...levelData,
          selectedSummary: valueData,
          generalAction: valueData,
          selectedState: { label: returnedRoles[1], value: returnedRoles[1] },
          selectedLga: { label: returnedRoles[2], value: returnedRoles[2] },
        })
      );
    } else if (returnedRoles.includes(stateAdmin)) {
      getActiveLgaInState(returnedRoles[1]);
      const valueData = {
        label: "State Summary",
        value: "state_summary",
      };
      setUserPermission(stateAdmin);
      dispatch(
        setLevelData({
          ...levelData,
          selectedSummary: valueData,
          generalAction: valueData,
          selectedState: { label: returnedRoles[1], value: returnedRoles[1] },
        })
      );
    } else {
      dispatch(
        setLevelData({
          ...levelData,
          selectedSummary: {
            label: "National Summary",
            value: "national_summary",
          },
        })
      );
      setUserPermission(nationalAdmin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActiveStates = () => {
    globalService
      .getActiveStates()
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(
            setActiveStates(
              data.message?.map(({ state }) => ({
                value: state,
                label: state,
              }))
            )
          );
          dispatch(
            setLevelData({
              ...levelData,
              states: data.message?.map(({ state }) => ({
                value: state,
                label: state,
              })),
            })
          );
        }
      })
      .then((err) => console.log("errrr", err));
  };
  const getActiveLgaInState = (state) => {
    globalService
      .getActiveLgasInState(state)
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(
            setLgasInState(
              data.message?.map((obj) => ({
                ...obj,
                value: obj?.local_government_area,
                label: obj?.local_government_area,
              }))
            )
          );
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getActiveWardsInLga = (state, lga) => {
    globalService
      .getWardsInLga(state, lga)
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(
            setWardsInLga(
              data.message?.map((obj) => ({
                ...obj,
                value: obj?.ward,
                label: obj?.ward,
              }))
            )
          );
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const handleSelectGeneralLevel = (value) => {
    const updateLevelData = (newGeneralAction) => {
      dispatch(
        setLevelData({
          ...levelData,
          generalAction: newGeneralAction,
          selectedSummary: newGeneralAction,
          selectedState: null,
          selectedLga: null,
          selectedWard: null,
        })
      );
    };
    if (userPermission === nationalAdmin) {
      switch (value.value) {
        case "national_summary":
          updateLevelData(value);
          break;
        case "state_summary":
        case "view_lga":
        case "view_ward":
          dispatch(
            setLevelData({
              ...levelData,
              generalAction: value,
              selectedSummary: value,
            })
          );
          break;
        default:
          break;
      }
    }
    if (userPermission === stateAdmin) {
      if (value.value === "state_summary") {
        getActiveLgaInState(returnedRoles[1]);
        dispatch(
          setLevelData({
            ...levelData,
            generalAction: value,
            selectedState: { label: returnedRoles[1], value: returnedRoles[1] },
            selectedSummary: value,
            selectedLga: null,
            selectedWard: null,
          })
        );
      } else if (value.value === "view_lga") {
        getActiveLgaInState(returnedRoles[1]);
        dispatch(
          setLevelData({
            ...levelData,
            generalAction: { value: "view_lga" },
            selectedState: { label: returnedRoles[1], value: returnedRoles[1] },
            selectedSummary: value,
            selectedLga: null,
            selectedWard: null,
          })
        );
      }
    }
    if (userPermission === lgaAdmin && value.value === "view_lga") {
      getActiveLgaInState(returnedRoles[1]);
      getActiveWardsInLga(returnedRoles[1], returnedRoles[2]);
      dispatch(
        setLevelData({
          ...levelData,
          generalAction: value,
          selectedSummary: value,
          selectedState: { label: returnedRoles[1], value: returnedRoles[1] },
          selectedLga: { label: returnedRoles[2], value: returnedRoles[2] },
          selectedWard: null,
        })
      );
    }
    if (value.value === "view_ward") {
      dispatch(
        setLevelData({
          ...levelData,
          generalAction: value,
          selectedSummary: value,
        })
      );
    }

    !activeStates?.length && getActiveStates();
  };

  const handleChangeState = (value) => {
    if (value?.value !== levelData?.selectedState?.value) {
      dispatch(setLgasInState([]));
      dispatch(
        setLevelData({
          ...levelData,
          selectedState: value,
          selectedLga: null,
          selectedWard: null,
        })
      );
    }
    getActiveLgaInState(value?.value);
  };

  const handleChangeLga = (value) => {
    if (value?.value !== levelData?.selectedLga?.value) {
      dispatch(setWardsInLga([]));
      dispatch(
        setLevelData({
          ...levelData,
          selectedLga: { value: value.value, label: value.label },
          selectedWard: null,
        })
      );
    }
    getActiveWardsInLga(value?.state, value?.value);
  };

  return {
    handleSelectGeneralLevel,
    levelData,
    activeStates,
    lgasInState,
    wardsInLga,
    handleChangeState,
    handleChangeLga,
    dispatch,
    selectedProgramArea,
    selectedBehaviour,
    userPermission,
    userRoles,
  };
};

export default useLevel;
