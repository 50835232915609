import React from "react";
import "./SlidingBar.css";
import { Link, useNavigate } from "react-router-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  getUser,
  removeUserSession,
  setUserLogOut,
} from "../../../../../utils/helpers";
import authService from "../../../../../services/auth.service";
import { toast } from "react-toastify";

const SlidingBar = () => {
  const user = getUser();
  const navigate = useNavigate();
  const handleLogout = () => {
    if (user) {
      authService
        .logout(user?.email)
        .then(({ message }) => {
          removeUserSession();
          toast.success("Successfully logged out");
          navigate("/login");
        })
        .then(() => {
          removeUserSession();
          navigate("/login");
        });
    }
    removeUserSession();
    navigate("/login");
  };
  return (
    <div className={`top-nav-sliding`}>
      <div className="sliding-content">
        <div className="sliding-closebtn">
          <HighlightOffIcon className="sliding-icon" />
          </div>
        <div className="sliding-links">
          <Link to="/dashboard">Dashboard</Link>
          <Link>Resources</Link>
          <Link to="/faqs_support">FAQs</Link>
          <Link to="/contact">Contact</Link>
          <Link>Profile</Link>
          <Link onClick={handleLogout}>Log out</Link>
        </div>
        <div className="sliding-text">Built by <span>Heimdall</span></div>
      </div>
    </div>
  );
};

export default SlidingBar;
