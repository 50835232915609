import { ReactComponent as HPVIcon } from "../assets/images/LIP/hpv-icon.svg";
import { ReactComponent as RoutineImmunizationIcon } from "../assets/images/LIP/roitine-immune-icon.svg";
import { ReactComponent as CoronaIcon } from "../assets/images/LIP/covid-icon.svg";
import { ReactComponent as CampaignIcon } from "../assets/images/LIP/campaign-icon.svg";
import { ReactComponent as CrossCuttingIcon } from "../assets/images/LIP/cross-cutting-icon.svg";
import moment from "moment";
export const levels = ["national", "state", "lg"];

export const indicators = [
  {
    id: 1,
    name: "Number of vaccines given disaggregated by antigen, LGA and period",
  },
  { id: 2, name: "Proportion of target population vaccinated" },
  { id: 3, name: "Opened vaccine wastage" },
  { id: 4, name: "Closed Vaccine wastage" },
  { id: 5, name: "Rate of vaccine wastage (opened and closed)" },
  { id: 6, name: "Opening balance" },
  { id: 7, name: "Closing balance" },
  { id: 8, name: "Stock out" },
  { id: 9, name: "Stock balance" },
  { id: 10, name: "Number of Syringes" },
  { id: 11, name: "Doses Opened" },
];

export const program_areas = [
  {
    id: 1,
    name: "COVID - 19",
    value: "Covid-19",
    value2: "COVID",
    icon: (
      <CoronaIcon
        className="program-area-icon"
        style={{ width: 50, height: 50 }}
      />
    ),
    active: true,
  },
  {
    id: 2,
    name: "HPV Vaccine",
    value: "HPV",
    value2: "HPV",
    icon: (
      <HPVIcon
        className="program-area-icon"
        style={{ width: 50, height: 50 }}
      />
    ),
    active: true,
  },
  {
    id: 3,
    name: "Routine Immunization",
    value: "R I",
    value2: "RI",
    icon: (
      <RoutineImmunizationIcon
        className="program-area-icon"
        style={{ width: 50, height: 50 }}
      />
    ),
    active: true,
  },
  {
    id: 4,
    name: "Campaign",
    value: "Campaign",
    value2: "Campaign",
    icon: (
      <CampaignIcon
        className="program-area-icon"
        style={{ width: 50, height: 50 }}
      />
    ),
    active: true,
  },
  {
    id: 5,
    name: "Cross-cutting",
    value: "Cross-cutting",
    value2: "Cross-cutting",
    icon: (
      <CrossCuttingIcon
        className="program-area-icon"
        style={{ width: 50, height: 50 }}
      />
    ),
    active: true,
  },
  // {
  //   id: 6,
  //   name: "Family Planning",
  //   value: "F P",
  //   icon: <FamilyPlanningIcon style={{ width: 50, height: 50 }} />,
  // },
];

export const dataSources = [
  {
    id: 1,
    name: "Social Media Data",
    value: "SOCIAL_MEDIA_DATA",
    activeProgramAreas: ["COVID", "HPV", "RI"],
  },
  {
    id: 2,
    name: "Administrative Data",
    value: "ADMINISTRATIVE_DATA",
    activeProgramAreas: ["COVID", "RI"],
  },
  {
    id: 3,
    name: "Program Data",
    value: "PROGRAM_DATA",
    activeProgramAreas: ["RI"],
  },
  {
    id: 4,
    name: "Survey Data",
    value: "SURVEY_DATA",
    activeProgramAreas: ["RI"],
  },
];

export const datePeriodOptions = [
  {
    label: "Last Month",
    value: "LAST MONTH",
    id: "LAST MONTH",
    startDate: moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
    resolution: "day",
    active: false,
  },
  {
    label: "Last Quarter",
    value: "LAST QUARTER",
    id: "LAST QUARTER",
    startDate: moment()
      .startOf("quarters")
      .subtract(1, "quarters")
      .format("YYYY-MM-DD"),
    endDate: moment()
      .startOf("quarters")
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    resolution: "week",
    active: false,
  },
  {
    label: "Last 6 months",
    value: "6 MONTHS",
    id: "6 MONTHS",
    startDate: moment()
      .subtract(6, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    resolution: "month",
    active: false,
  },
  {
    label: "Last 12 months",
    value: "12 MONTHS",
    id: "12 MONTHS",
    startDate: moment()
      .subtract(12, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    resolution: "month",
    active: true,
  },
  {
    label: "Custom",
    value: "Custom",
    id: "Custom",
    startDate: new Date('1/1/21'),
    endDate: new Date(),
    resolution: "month",
    active: false,
  },
];

export const feedBacks = [];

export const insightRecommendationText =
  "These records digitally store a patient's medical history, includingdiagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient’s medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient’s medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient's medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient’s medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient’s medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care.";

export const availableStates = ["Cross River", "Jigawa"];

export const availableLgas = ["Hadejia", "Kafin Hausa"];

export const colors = [
  "#8E30FF",
  "#0263FF",
  "#FF7723",
  "#152d6e",
  "#ffc60c",
  "#009900",
  "#0066ff",
  "#ff9900",
];
