import React from "react";
import { Link } from "react-router-dom";
import "./Error404Page.css";
import { ReactComponent as Error404Img } from "../../assets/images/LIP/404Image.svg";
const Error404Page = () => {
  return (
    <div className="error-page-root-container">
      <div classname="error-page-image">
        <Error404Img style={{ width: 400, height: 400 }} />
      </div>
      <div className="error-title">404</div>
      <p className="error-sub-title">Page not found</p>
      <Link className="back-home-button" to="/">
        Back to Home
      </Link>
    </div>
  );
};

export default Error404Page;
