import React from 'react'
import useBlog from './Hook/useBlog';

const Blog = () => {
    const{
        blogData
    } = useBlog();
  return (
    <>
    <div dangerouslySetInnerHTML={{ __html: blogData }} />
    </>
  )
}

export default Blog