import React, { useState } from "react";
import { Link } from "react-router-dom";
import PopupModal from "../../components/PopupModal";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import "../Login/Login.css";
import sydani from "../../assets/images/LIP/sydani-logo.svg";
import nphe from "../../assets/images/LIP/nphe.svg";
import acsm from "../../assets/images/LIP/acsmlogo1.svg";
import { useDispatch } from "react-redux";
import {
  setPopupChildren,
  setSelectedLevel,
  togglePopup,
} from "../../redux/actions/global.action";
import { setToken, setUser, setUserLogin } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { ForgottenPassword } from "./components";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import authService from "../../services/auth.service";
import { RotatingLines } from "react-loader-spinner";
import { setMyData } from "../../redux/actions/user.action";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  const handleLogin = () => {
    if (!email.trim().length) {
      return toast.error("Invalid email");
    }

    if (!password.trim().length) {
      return toast.error("Invalid password");
    }
    setLoggingIn(true);
    authService
      .login(email, password)
      .then(({ message, home_page, full_name }) => {
        if (message.success) {
          toast.success("Successfully logged in");
          setToken(`${message.api_key}:${message.api_secret}`);
          setUser({ ...message, home_page, full_name });
          dispatch(setMyData({ ...message, home_page, full_name }));
          navigate("/");
        } else {
          toast.error(message.message);
        }
      })
      .catch(({ error }) => {
        toast.error(error);
      })
      .finally(() => setLoggingIn(false));
  };
  return (
    <div className="login-root-container">
      <div className="Login_Wrapper">
        <div className="Login_Card_Background">
          <div className="Login_Card">
            <div className="Login_Header">
            <span className="Login_Header_Logo">
                <img className='header-logo-height' src={acsm} alt="acsm_logo"/>
              </span>
              <span className="Login_Header_FormTitle">
                <Link id="login_header_Home" to="/login">
                <ArrowCircleLeftIcon className="header-icon"/>
                <span>Back to Login</span>
                </Link>
              </span>
            </div>
            <div className="Login_Form">
              <div className="Login_TextWrapper">
                <label className="Login_Form_Label" htmlFor="">
                  Email/ Username
                </label>
                <input
                  value={email}
                  className="Login_Form_Input"
                  type="text"
                  name=""
                  id=""
                  onChange={({ target }) => setEmail(target.value)}
                />
              </div>

              <div
                className="Login_Form_ForgottenPassword"
              >
                <span>A request will be sent to the administrator of the ACSM Data Hub. Kindly check your email for details.</span>
              </div>
              <button
                // onClick={() => {
                //   setUserLogin();
                //   navigate("/");
                // }}
                onClick={handleLogin}
                className="Login_LoginBtn"
              >
                {loggingIn ? (
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                ) : (
                  "Request Password Reset"
                )}
              </button>
            </div>
          </div>
          <div className="Login_Bottom">
            <div className="Login_Bottom_Powered_Container">
              <div className="Login_Bottom_Powered">
                <span>Powered By</span>
                <span className="Login_Bottom_Logo">
                  <img className='logo-height' src={sydani} alt="sydani-logo"/>
                </span>
              </div>
            </div>
            <div className="Login_Bottom_Partner_Container">
              <div className="Login_Bottom_Partner">
                <span>
                In partnership with
                </span>
                <span className="Login_Bottom_Logo">
                <img className='logo-height' src={nphe} alt="nphe-logo"/>
                </span>
                </div>
            </div>
          </div>
          <span className="Bottom-span">© 2023 Copyright LIP. All Rights Reserved</span>

        </div>
        <PopupModal />
      </div>
    </div>
  );
}
