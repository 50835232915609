import React, { useEffect, forwardRef, useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TuneIcon from "@mui/icons-material/Tune";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useVisualizeSummary } from "../../hooks/useVisualizeSummary";
import { Skeleton } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const DoughnutChart = forwardRef(({
  handleOpenData,
  handleOpenCustomize,
  setPageNav,
}, ref) => {
  const [isFixed, setIsFixed] = useState(false);
  const {
    gettingFinalData,
    chart,
    selectedIndicators,
    period,
    levelData,
    indicatorColors,
  } = useVisualizeSummary();
  const { selectedProgramArea, dataSource } = useSelector(
    (state) => state.global
  );

  const chartDatas =
    chart?.map((data) => {
      const total = data.data.reduce(
        (a, currentValue) => a + Number(currentValue?.percentage),
        0
      );
      if (data?.data?.length === 1) {
        if(data?.data[0]?.percentage < 100){
          return {
            chart: [
              {
                name: data?.data[0]?.option,
                value: data?.data[0]?.percentage,
                color: indicatorColors[0],
                val: data?.data[0]?.val,
              },
              {
                name: "",
                value: 100 - data?.data[0]?.percentage,
                color: "#00000029",
                val: 0,
              },
            ],
            data,
          };
        } else {
          return {
            chart: [
              {
                name: data?.data[0]?.option,
                value: data?.data[0]?.percentage,
                color: indicatorColors[0],
                val: data?.data[0]?.val,
              }
            ],
            data,
          };
        }
      } else {
        return {
          chart: data.data?.map((d, index) => {
            return {
              name: d?.option || "",
              value: d?.percentage || 100 - total,
              color: d?.percentage === 0 ? "#00000029" : indicatorColors[index],
              val: d?.val || 0,
            };
          }),
          data,
        };
      }
    }) || [];
    console.log(chartDatas);

  // const RADIAN = Math.PI / 180;
  // const renderCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   index,
  //   value,
  //   name,
  //   val,
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <>
  //       <text
  //         className="pie-font"
  //         x={x}
  //         y={y}
  //         fill="white"
  //         width={30}
  //         textAnchor="middle"
  //         dominantBaseline="central"
  //       >
  //         {`${name} ${val == 0 ? 0 : value}% `}
  //         {val && ` (${val})`}
  //       </text>
  //     </>
  //   );
  // };
  function convertDateFormat(originalDateString) {
    const originalDate = moment(originalDateString, "YYYY-MM-DD");

    const formattedDate = originalDate.format("MMMM D, YYYY");

    return formattedDate;
  }

  // console.log(
  //   chartDatas?.filter(
  //     (chart) => chart?.data?.chart_type?.includes("Doughnut") && chart
  //   )
  // );
  useEffect(() => {
    const getReturnedIndicators = () => {
      const allTypes = ["Pie", "Table", "Doughnut", "bar"];
      let resultType = [];
      const filteredIndicators = selectedIndicators
        .filter((data) => !data?.chart_type.includes("Doughnut"))
        .map(({ title, chart_type }) => ({ title, chart_type }));
      const getallStrings = filteredIndicators.map((data) => data.title);
      filteredIndicators.forEach((data) => {
        data.chart_type.forEach((d) => {
          resultType.push(d);
        });
      });
      const newResultType = [...new Set(resultType)];
      const filteredResultType = allTypes.filter(
        (data) => !newResultType.includes(data)
      );
      const faultingIndicators = {
        getallStrings: getallStrings.join(", "),
        getallTypes: filteredResultType.join(", "),
      };
      return {
        message: `The indicators: ${faultingIndicators.getallStrings}, cannot be viewed using this visualization type. Change the visualization type to Table or Bar chart to view all indicators.`,
        getallStrings,
      };
    };
    const response = getReturnedIndicators();
    console.log(response.getallStrings);
    console.log(selectedIndicators);
    if (response.getallStrings.length >= 1 && selectedIndicators.length > 0) {
      if(response.getallStrings.length > 3){
        toast.info(`Please note that some indicators cannot be viewed using this visualization type. Change the visualization type to Table or Bar chart to view all indicators`);
      }else {
        toast.info(response.message);
      }
    }
  }, [selectedIndicators]);
  const divStyle = {
    position: isFixed ? 'fixed' : 'static',
    top: isFixed ? '70px' : 'auto',
    zIndex: isFixed ? 100 : 0,
    backgroundColor: '#ffffff',
    width: '100%',
  };
  // const { openData } = handleModals;
  // console.log(openData);

  useEffect(() => {
    const handleScroll = () => {
      // You can adjust the scroll threshold based on your needs
      const scrollThreshold = 100;

      if (window.scrollY > scrollThreshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className="chart-root-container">
        <div className="chart-root-top" style={divStyle}>
          <p
            id="bold"
            onClick={() => setPageNav("default")}
            style={{ cursor: "pointer", color: "#011b60" }}
          >
            <ArrowCircleLeftIcon className="header-icon" />
            Change visualization type
          </p>
          |
          <p>
            {selectedProgramArea.name}
          </p>
          |
          <p>
            {dataSource}
          </p>
          |
          <p>
            {convertDateFormat(period?.startDate)} -{" "}
            {convertDateFormat(period?.endDate)}
          </p>
          |
          {levelData?.generalAction?.value === "national_summary" ? (
            <p>Nigeria</p>
          ) : (
            <p>
              {levelData?.selectedWard && `${levelData?.selectedWard?.label}, `}
              {levelData?.selecteLga && `${levelData?.selecteLga?.label}, `}
              {levelData?.selectedState &&
                `${levelData?.selectedState?.label} State`}
            </p>
          )}
          <button onClick={handleOpenData}>
            <CloudDownloadIcon className="header-icon" /> Export PDF/XLSX
          </button>
          <button onClick={handleOpenCustomize}>
            <TuneIcon className="header-icon" /> Customize
          </button>
        </div>
        <div className="charts-main-container" ref={ref}>
          {gettingFinalData ? (
            selectedIndicators?.map((chart, index) => (
              <div
                key={index}
                style={{
                  width: "95%",
                }}
              >
                <Skeleton
                  style={{
                    width: "100%",
                    margin: "-50px auto",
                  }}
                  height={500}
                />
              </div>
            ))
          ) : chartDatas?.filter(
              (chart) => chart?.data?.chart_type?.includes("Doughnut") && chart
            )?.length ? (
            chartDatas
              ?.filter(
                (chart) =>
                  chart?.data?.chart_type?.includes("Doughnut") && chart
              )
              ?.map((chartData, i) => (
                <div key={i} className="chart-container">
                  <div className="chart-spacing">
                    <div className="chart-root-top-text">
                      {chartData?.data?.title}
                    </div>
                    <ResponsiveContainer
                      className="responsive-chart"
                      debounce={300}
                      width="100%"
                      height={300}
                    >
                      <PieChart width={400} height={200}>
                        <Pie
                          data={chartData?.chart}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          // label={renderCustomizedLabel}
                          outerRadius={140}
                          innerRadius={60}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {chartData?.chart?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="pie-chart-legend-container">
                  {chartData?.data?.single_choice === 0 ?
                    chartData?.chart.map((data, index) => (
                    <p>
                      <span
                        className="legend-color"
                        style={{ backgroundColor: chartData?.chart[index]?.color }}
                      />
                      {chartData?.chart[index]?.name} -{" "}
                      {(chart[index].value !== null && `${chartData?.chart[index]?.value}%`)}
                    </p>
                    ))
                    : (
                      <p>
                      <span
                        className="legend-color"
                        style={{ backgroundColor: chartData?.chart[0]?.color }}
                      />
                      {chartData?.chart[1]?.name} -{" "}
                      {(chartData?.chart[1]?.value !== null && `${chartData?.chart[0]?.value}%`)}
                    </p>
                    )
                  }
                  </div>
                  {dataSource === "Survey Data" ||
                    dataSource === "Administrative Data" ? (
                      <div className="chart-root-b0ttom">
                        {chartData?.data?.question}
                      </div>
                    ) : (
                      ""
                    )}
                </div>
              ))
          ) : (
            <div style={{ color: "#011b60", marginTop: 100 }}>
              No record found
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default DoughnutChart;
