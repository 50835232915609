export const steps = [
  {
    id: 1,
    name: "Vaccine type",
    value: "vaccine_type",
    route: "/vaccine_type",
  },
  {
    id: 2,
    name: "Data type",
    value: "data_select",
    route: "/data_select",
  },
  {
    id: 3,
    name: "Level",
    value: "level",
    route: "/vaccine_type/level",
  },
  {
    id: 4,
    name: "Time frame",
    value: "period",
    route: "/vaccine_type/period",
  },
  {
    id: 5,
    name: "Visualization Type",
    value: "visualization",
    route: "/vaccine_type/visualization",
  },
];

export const generalLevelOptions = [
  {
    label: "National Summary",
    value: "national_summary",
  },
  {
    label: "State Summary",
    value: "state_summary",
  },
  {
    label: "View LGA",
    value: "view_lga",
  },
  {
    label: "View Ward",
    value: "view_ward",
  },
];

export const behaviouralCategories = [
  {
    label: "Health Facility",
    value: "Health Facility",
  },
  {
    label: "Community",
    value: "Community",
  },
  {
    label: "Social",
    value: "Social",
  },
];

export const roles = {
  nationalAdmin: 'NationalAdmin',
  stateAdmin: 'StateAdmin',
  lgaAdmin: 'LgaAdmin',
}

export const visualizationTypes = ["Table", "bar"];
