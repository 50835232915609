import React, { useState, useRef } from "react";
import "./Summary.css";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Modal, Typography } from "@mui/material";
import { setIndicatorColors } from "../../../../redux/actions/global.action";
import { ChromePicker } from "react-color";
import CloseIcon from "@mui/icons-material/Close";
import xlxLogo from "../../../../assets/images/LIP/xsls-icon.svg";
import pdfLogo from "../../../../assets/images/LIP/pdf-icon.svg";
import DoughnutChart from "./components/DoughnutChart";
import { useVisualizeSummary } from "./hooks/useVisualizeSummary";
import useProgramAreas from "../../../ProgramAreas/hooks/useProgramAreas";
import { chartViewTypes } from "./constants";
import PieChartp from "./components/PieChart";
import BarChartb from "./components/BarChart";
import TableView from "./components/TableView";
import { RotatingLines } from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import html2pdf from 'html2pdf.js'

const Summary = () => {
  const { indicatorColors } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const [selectColorIndex, setSelectColorIndex] = useState(0);
  const [showPicker, setShowPicker] = useState(false);

  const {
    handleModals,
    exportType,
    setExportType,
    downloadFinalData,
    downloadBehaviouralData,
    downloading,
  } = useProgramAreas();

  const {
    openCustomize,
    handleCloseCustomize,
    handleOpenCustomize,
    openData,
    handleOpenData,
    handleCloseData,
  } = handleModals;
  const [pageNav, setPageNav] = useState("default");
  const downloadRef = useRef();

  const style1 = {
    position: "absolute",
    top: "25%",
    right: "0",
    // transform: "translate(100%, -50%)",
    width: 300,
    height: 400,
    bgcolor: "background.paper",
    color: "#011B60",
    borderRadius: 2,
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };

  const imgBox = [
    {
      img: xlxLogo,
      alt: "xlx-Logo",
      desc: "Download Data with data elements (xlx)",
      active: true,
      type: "excel",
    },
    // {
    //   img: csvLogo,
    //   alt: "csv-Logo",
    //   desc: "Download data in CSV",
    //   active: false,
    // },
    {
      img: pdfLogo,
      alt: "pdf-Logo",
      desc: "Download data in PDF",
      active: false,
      type: "pdf",
    },
  ];

  const customBox = indicatorColors.map((data) => {
    return {
      color: data,
      desc: data,
    };
  });

  const handlePickerToggle = async (index) => {
    await setSelectColorIndex(index);
    await setShowPicker((prev) => !prev);
  };

  const handleColorChange = (color) => {
    dispatch(
      setIndicatorColors(
        indicatorColors?.map((c, i) => (selectColorIndex === i ? color.hex : c))
      )
    );
  };

  const {
    getFinalData,
    getFinalData2,
    getFinalBehaviouralData,
    getFinalBehaviouralData2,
    dataType,
  } = useVisualizeSummary();

  const handleFetchData = (tableType) => {
    switch (tableType) {
      case "table":
        dataType === "PERFORMANCE_DATA"
          ? getFinalBehaviouralData2()
          : getFinalData2();
        setPageNav(tableType);
        break;
      case "bar":
        dataType === "PERFORMANCE_DATA"
          ? getFinalBehaviouralData2()
          : getFinalData2();
        setPageNav(tableType);
        break;
      case "doughnut":
        dataType === "PERFORMANCE_DATA"
          ? getFinalBehaviouralData()
          : getFinalData();
        setPageNav(tableType);
        break;
      case "pie":
        dataType === "PERFORMANCE_DATA"
          ? getFinalBehaviouralData()
          : getFinalData();
        setPageNav(tableType);
        break;
      default:
        break;
    }
  };

  const handlePrint = useReactToPrint({
    content: () => downloadRef.current,
    documentTitle: `test.pdf`,
		copyStyles: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
			if (document) {
				const html = document.getElementsByTagName('html')[0];
        const marginOptions = {
          top: 20,
          bottom: 20,
          left: 50,
          right: 50,
        };
				await html2pdf().from(html).set(marginOptions).save();
			}
    },
  });

  const onCompleteExport = () => {
    if (exportType === "pdf") {
      handlePrint();
    } else {
      if (dataType === "PERFORMANCE_DATA") {
        downloadBehaviouralData();
      } else {
        downloadFinalData();
      }
    }
  };

  return (
    <>
      <Modal
        open={openCustomize}
        onClose={handleCloseCustomize}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="summary-main-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Customize charts to your colour preferences
          </Typography>
          <Box className="summary-customize">
            {showPicker && (
              <>
                <ChromePicker
                  className="sumamry-color"
                  color={indicatorColors[selectColorIndex]}
                  onChange={handleColorChange}
                  onChangeComplete={handleColorChange}
                />
                <button
                  className="color-close-btn"
                  onClick={() => setShowPicker((prev) => false)}
                >
                  <CloseIcon />
                </button>
              </>
            )}
            {customBox.map((data, i) => (
              <Box
                className="summary-customize-box"
                onClick={() => handlePickerToggle(i)}
              >
                <Box bgcolor={data.color} height="30px" width="30px"></Box>
                <Typography ml={3}>{data.desc}</Typography>
                {/* <ChromePicker /> */}
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openData}
        onClose={handleCloseData}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-container">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Export Visualization Data
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Select your preferred format to view program data and export to
            device
          </Typography>
          <Box className="modal-content">
            {imgBox.map((data) => (
              <Box flex="1" onClick={() => setExportType(data?.type)}>
                <Box
                  className={
                    data?.type === exportType
                      ? "img-box img-box-active"
                      : "img-box"
                  }
                >
                  <img className="summary-img" src={data.img} alt={data.alt} />
                </Box>
                <Typography mt={2} variant="subtitle2">
                  {data.desc}
                </Typography>
              </Box>
            ))}
          </Box>
          <Button
            mt={4}
            variant="contained"
            onClick={onCompleteExport}
            className="summary-box-btn"
          >
            {downloading ? (
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Complete Export"
            )}
          </Button>
        </Box>
      </Modal>
      {pageNav === "default" ? (
        <div className="summary-root-container">
          <div className="summary-content" id="chart-area">
            <div className="summary-top">
              <div className="top-left-text">
                Please select your preferred type
              </div>
              <div>
                Visualise your ACSM data using a table structure <br /> view to
                gain more useful insights
              </div>
            </div>
            <div className="summary-bottom-container">
              {chartViewTypes.map((chart, i) => (
                <div
                  className="summary-bottom"
                  onClick={() => handleFetchData(chart.value)}
                >
                  <img
                    className="summary-img"
                    src={chart.icon}
                    alt="Table-View"
                  />
                  <div className="summary-img-desc">
                    <p className="summary-bold">{chart.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : pageNav === "table" ? (
        <TableView
          handleOpenData={handleOpenData}
          handleOpenCustomize={handleOpenCustomize}
          setPageNav={setPageNav}
          ref={downloadRef}
        />
      ) : pageNav === "doughnut" ? (
        <DoughnutChart
          handleOpenData={handleOpenData}
          handleOpenCustomize={handleOpenCustomize}
          setPageNav={setPageNav}
          ref={downloadRef}
        />
      ) : pageNav === "pie" ? (
        <PieChartp
          handleOpenData={handleOpenData}
          handleOpenCustomize={handleOpenCustomize}
          setPageNav={setPageNav}
          ref={downloadRef}
        />
      ) : pageNav === "bar" ? (
        <BarChartb
          handleOpenData={handleOpenData}
          handleOpenCustomize={handleOpenCustomize}
          setPageNav={setPageNav}
          ref={downloadRef}
        />
      ) : null}
    </>
  );
};

export default Summary;
