import { useEffect } from "react";
import { useState } from "react";

const useBlog = () => {
  const [blogData, setBlogData] = useState(null);
  useEffect(() => {
    const data = localStorage.getItem("Blog");
    const sanitizedContent = data.replace(/'/g, '').replace(/"/g, '');
    setBlogData((prev) => sanitizedContent);
    return () => {
      setBlogData((prev) => null);
    };
  }, []);
  return {
    blogData,
  };
};

export default useBlog;