import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useCardBottom = ({category, getDataByCategory}) => {
  const navigate = useNavigate();
  const [categoryBlogs, setCategoryBlogs] = useState(null);
  const handleClickBlog = (data) => {
      const updatedtext = data?.title?.toLowerCase().replace(/\s+/g, '-');
      localStorage.setItem("Blog", JSON.stringify(data?.content))
      if(data.custom_blog_destination === "External"){
        window.open(`/resource/${updatedtext}`, '_blank');
      } else {
        navigate(`/resource/${updatedtext}`);
      }
  }
  useEffect(() => {
    const data = getDataByCategory(category.toLowerCase());
    setCategoryBlogs((prev) => data);

    return () => setCategoryBlogs((prev) => null);
  }, []);
  return {
    categoryBlogs,
    handleClickBlog,
  };
};

export default useCardBottom;
