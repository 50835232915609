import tableView from "../../../../assets/images/LIP/spreadsheet.svg";
import barChart from "../../../../assets/images/Bar chart.svg";
import pieChart from "../../../../assets/images/Pie Chart.svg";
import doughnutChart from "../../../../assets/images/Donut.svg";

export const chartViewTypes = [
  { name: "Table View", value: "table", icon: tableView },
  { name: "Bar Chart", value: "bar", icon: barChart },
  { name: "Pie Chart", value: "pie", icon: pieChart },
  { name: "Doughnut Chart", value: "doughnut", icon: doughnutChart },
];
