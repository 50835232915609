import React from "react";
import "./SelectProgramArea.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProgramArea,
  setIndicators,
  setDataSource,
  setSelectedIndicators,
} from "../../../../redux/actions/global.action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SelectProgramArea = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const { selectedProgramArea, dataType, programAreas } = useSelector(
    (state) => state.global
  );
  const navigate = useNavigate();

  const handleSelectProgramArea = (area) => {
    if (["Campaign", "Cross-cutting"].includes(area.value)) {
      return toast.info("Not available");
    }
    dispatch(setSelectedProgramArea(area));
    if (selectedProgramArea?.program_area !== area?.program_area) {
      dispatch(setDataSource(null));
      dispatch(setIndicators([]));
      dispatch(setSelectedIndicators([]));
    }

    if (dataType === "PERFORMANCE_DATA") {
      setActiveStep(2);
    } else {
      setActiveStep(1);
    }
  };

  return (
    <div className="base-component-root">
      <div className="select_program_text">
        Select a vaccine type to get started
      </div>
      <div className="select_program_areas_container">
        {programAreas?.map((area, index) =>
          dataType === "PERFORMANCE_DATA" ? (
            !["Campaign", "Cross-cutting"].includes(area.value) && (
              <div
                onClick={() => handleSelectProgramArea(area)}
                className={`individual-area-container ${
                  selectedProgramArea?.value == area?.value &&
                  "active-vaccine-type"
                }`}
                key={index}
              >
                {area.icon} <p className="area_text">{area.name}</p>
              </div>
            )
          ) : (
            <div
              onClick={() => handleSelectProgramArea(area)}
              className={`individual-area-container ${
                selectedProgramArea?.value == area?.value &&
                "active-vaccine-type"
              }`}
              key={index}
              style={{
                ...(["Campaign", "Cross-cutting"].includes(area.value)
                  ? { filter: "blur(1px)", cursor: "not-allowed" }
                  : {}),
              }}
            >
              {area.icon} <p className="area_text">{area.name}</p>
            </div>
          )
        )}
      </div>
      <div
        className="back_to_login_text"
        onClick={() => navigate("/dashboard")}
      >
        <span>Back to Home</span>
      </div>
    </div>
  );
};

export default SelectProgramArea;
