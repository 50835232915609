import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setChart, setChart2 } from "../../../../../redux/actions/global.action";
import globalService from "../../../../../services/global.service";

export const useVisualizeSummary = () => {
  const [gettingFinalData, setGettingFinalData] = useState(false);
  const dispatch = useDispatch();
  const {
    period,
    dataType,
    selectedCategories,
    selectedIndicators,
    levelData,
    selectedBehaviour,
    selectedProgramArea,
    dataSource,
    chart,
    chart2,
    indicatorColors,
  } = useSelector((state) => state.global);

  const getFinalData = () => {
    if (!dataSource) {
      return toast.info("Please select vaccine type");
    }
    if (!selectedIndicators?.length) {
      return toast.info("Please select indicators to visualize");
    }
    setGettingFinalData(true);
    globalService
      .getFinalData({
        program_area: selectedProgramArea?.name,
        data_source: dataSource,
        indicator_ids: String(
          selectedIndicators?.map((indicator) => indicator?.ind_id)
        ),
        period: `${period?.startDate},${period?.endDate}`,
        ...(levelData?.selectedState && {
          state: levelData?.selectedState?.value,
        }),
        ...(levelData?.selectedLga && { lga: levelData?.selectedLga?.value }),
        ...(levelData?.selectedWard && {
          ward: levelData?.selectedWard?.value,
        }),
      })
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(setChart(data?.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setChart([]));
      })
      .finally(() => {
        setGettingFinalData(false);
      });
  };

  const getFinalData2 = () => {
    if (!dataSource) {
      return toast.info("Please select vaccine type");
    }
    if (!selectedIndicators?.length) {
      return toast.info("Please select indicators to visualize");
    }
    setGettingFinalData(true);
    globalService
      .getFinalData2({
        program_area: selectedProgramArea?.name,
        data_source: dataSource,
        indicator_ids: String(
          selectedIndicators?.map((indicator) => indicator?.ind_id)
        ),
        period: `${period?.startDate},${period?.endDate}`,
        ...(levelData?.selectedState && {
          state: levelData?.selectedState?.value,
        }),
        ...(levelData?.selectedLga && { lga: levelData?.selectedLga?.value }),
        ...(levelData?.selectedWard && {
          ward: levelData?.selectedWard?.value,
        }),
      })
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(setChart2(data?.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setChart2([]));
      })
      .finally(() => {
        setGettingFinalData(false);
      });
  };

  const getFinalBehaviouralData = () => {
    setGettingFinalData(true);
    globalService
      .getFinalBehaviouralData({
        program_area: selectedProgramArea?.name,
        data_type: "behavioural",
        period: `${period?.startDate},${period?.endDate}`,
        data_source: selectedBehaviour?.value,
        ...(levelData?.selectedState && {
          state: levelData?.selectedState?.value,
        }),
        ...(levelData?.selectedLga && { lga: levelData?.selectedLga?.value }),
        ...(levelData?.selectedWard && {
          ward: levelData?.selectedWard?.value,
        }),
      })
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(setChart(data?.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setChart([]));
      })
      .finally(() => {
        setGettingFinalData(false);
      });
  };

  const getFinalBehaviouralData2 = () => {
    setGettingFinalData(true);
    globalService
      .getFinalBehaviouralData2({
        program_area: selectedProgramArea?.name,
        data_type: "behavioural",
        period: `${period?.startDate},${period?.endDate}`,
        data_source: selectedBehaviour?.value,
        ...(levelData?.selectedState && {
          state: levelData?.selectedState?.value,
        }),
        ...(levelData?.selectedLga && { lga: levelData?.selectedLga?.value }),
        ...(levelData?.selectedWard && {
          ward: levelData?.selectedWard?.value,
        }),
      })
      .then((data) => {
        if (data?.message && Array.isArray(data?.message)) {
          dispatch(setChart2(data?.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setChart2([]));
      })
      .finally(() => {
        setGettingFinalData(false);
      });
  };

  return {
    gettingFinalData,
    getFinalData,
    getFinalData2,
    chart,
    chart2,
    selectedIndicators,
    period,
    levelData,
    indicatorColors,
    getFinalBehaviouralData,
    getFinalBehaviouralData2,
    dataType,
    selectedCategories,
    dataSource,
  };
};
