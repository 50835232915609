import React, { useState } from "react";
import "./Period.css";
import { useDispatch, useSelector } from "react-redux";
// import {
//   AddCircle,
//   ConnectingAirportsOutlined,
//   ConstructionOutlined,
//   HighlightOff,
// } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { datePeriodOptions } from "../../../../utils/constants";
import { DateRangePicker } from "react-date-range";
// import EmojiIcon from '@atlaskit/icon/glyph/emoji';
import DateRangeSelector from "../../../../components/DateRangeSelector/DateRangeSelector";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { setPeriod } from "../../../../redux/actions/global.action";
import usePeriod from "./Hook/usePeriod";
import moment from "moment";
import Select, { components } from "react-select";
import { convertToNewDateFormat } from "../../../../utils/helpers";

const Period = () => {
  const { period } = useSelector((state) => state.global);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: convertToNewDateFormat(period?.startDate),
      endDate: convertToNewDateFormat(period?.endDate),
      key: "selection",
    },
  ]);
  const { yearsOption, showCalendar, setShowCalendar } = usePeriod({ period, setSelectionRange });
  const previewColor = {
    startDate: convertToNewDateFormat(period?.startDate),
    endDate: convertToNewDateFormat(period?.endDate),
    color: ["#0A5FFF"],
  };
  const [dateOptions, setDateOptions] = useState("12 MONTHS");
  const [showSingleCalendar, setShowSingleCalendar] = useState(false);

  const dispatch = useDispatch();

  const handleCalender = (range) => {
    console.log(range);
    setSelectionRange([range.selection]);

    const { startDate, endDate } = range.selection;
    dispatch(
      setPeriod({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      })
    );
  };

  const handleDatePicker = (options) => {
    setDateOptions((prev) => options.label);
    datePeriodOptions.map((value) => {
      if (value.id !== options.id) {
        if (value.active) {
          value.active = false;
        }
      }
      options.active = true;
    });
    const newSelectionRange = {
      startDate: new Date(options.startDate),
      endDate: new Date(options.endDate),
      key: "selection",
    };
    setSelectionRange([newSelectionRange]);

    const { startDate, endDate } = newSelectionRange;
    dispatch(
      setPeriod({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      })
    );
  };

  const getFormattedDay = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const dayFormatter = date.toLocaleDateString(undefined, options);
    // const day = dayFormatter.format(date);
    return dayFormatter;
  };
  const active = (option) => {
    if (option.custom) {
      return true;
    } else if (
      option?.startDate == period?.startDate &&
      option?.endDate == period?.endDate
    ) {
      return true;
    } else {
      return false;
    }
  };
  const indicatorStyle = {
    backgroundColor: "#E3E9FD",
    color: "#011B60",
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator
        className="indicatorContainer"
        style={indicatorStyle}
        {...props}
      >
        <CalendarTodayIcon />
      </components.DropdownIndicator>
    );
  };

  const toggleCalendar = ({label}) => {
    console.log(label)
    if(label === "From"){
      setShowSingleCalendar(true)
    } else {
      setShowSingleCalendar(false)
      }
      setShowCalendar(prev => !prev)
  }
  return (
    <div className="period-root-container">
      <div className="period-inner-root-container">
        <div className="period-left-container">
          <div className="period-left">
            {datePeriodOptions.map((options) => (
              <>
                <div
                  key={options.id}
                  className={options.active && "period-category"}
                  onClick={() => handleDatePicker(options)}
                >
                  {options.label}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="period-right-container">
          <div className="period-right">
            <div className="period-right-top">
              <div className="calender-label">
                {datePeriodOptions[4].active &&
                  yearsOption.map((data, index) => (
                    <label key={index}>
                      <div className="calendar-input" onClick={() => toggleCalendar({label: data.defaultValue.label})}>
                        <input value={data.defaultValue.label} />
                        <CalendarTodayIcon onClick={() => setShowCalendar(prev => !prev)}/>
                      </div>
                      {showCalendar && (
                        <div
                          className={`calendar-container${index} ${
                            showSingleCalendar ? "show1" : "show2"
                          }`}
                        >
                          <Calendar
                            onChange={data.onChange}
                            value={data.defaultValue.value}
                          />
                        </div>
                      )}
                      {/* <Select
                        className="calendar-dropdown"
                        components={{ DropdownIndicator }}
                        options={data.getAllYears}
                        isClearable={false}
                        defaultValue={data.defaultValue}
                        onChange={(value) => data.onChange(value)}
                      ></Select> */}
                    </label>
                  ))}
              </div>
              <div>
                <span>{getFormattedDay(selectionRange[0].startDate)} - </span>

                <span> {" " + getFormattedDay(selectionRange[0].endDate)}</span>
              </div>
            </div>
            <div className="period-right-bottom">
              <div className="period-dropdown-container">
                {/* <div className={"period-dropdown"}>
                  <div className="dropdown-button" onClick={() => setToggleDropdown(prev => !prev)}>
                    {toggleDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    <span>{dateOptions}</span>
                  </div>
                  {toggleDropdown && (
                    <div className="dropdown-container">
                      {datePeriodOptions.map((options) => (
                        <>
                          <div
                            key={options.id}
                            className={`${
                              options.active && "period-category"
                            } dropdown-category`}
                            onClick={() => handleDatePicker(options)}
                          >
                            {options.label}
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </div> */}
                <Select
                  className="select-dropdown"
                  options={datePeriodOptions}
                  isClearable={false}
                  defaultValue={datePeriodOptions[datePeriodOptions.length - 1]}
                  onChange={(value) => handleDatePicker(value)}
                />
                {/* </div> */}
              </div>
              <div className="right-bottom-content">
                <DateRangePicker
                  onChange={handleCalender}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectionRange}
                  direction="horizontal"
                  maxDate={new Date()}
                  displayMode="dateRange"
                  startDatePlaceholder="From"
                  rangeColors={["#0A5FFF"]}
                  showPreview={true}
                  color={previewColor}
                  className="data-bottom"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Period;
