export const faqs = [
  {
    title: "General information",
    active: true,
    desc: [
      {
        question: "What is the ACSM Data hub, and what is its purpose?",
        status: false,
        answer: "The ACSM Data hub is a centralized data storage system designed to collect and manage real-time ACSM data for Routine Immunization (RI), COVID-19, and new vaccines' such as HPV. Its purpose is to serve as a robust repository for ACSM data to facilitate informed decision-making for demand generation.",
      },
      {
        question: "Who is the intended user of the ACSM data hub?",
        status: false,
        answer: "The data hub will facilitate decision making for demand generation efforts. Its functionality makes it useful to government agencies, health care professionals, development partners, donor agencies, researchers, community-based organizations and anyone seeking demand generation data for immunization programs.",
      },
      {
        question: "What data sources are integrated into the dashboard?",
        status: true,
        answer:
          "The dashboard integrates data from diverse sources, it covers ACSM program data, social listening findings and client perception data. Program data is from Primary Health Care agencies at the national, state and LGA level, healthcare facilities, and partners implementing demand generation activities. Social listening data is from social media platforms such as Twitter, Facebook, blogs etc. Client perception is gotten from BeSD surveys conducted through exit interviews, community polls, SMS and IVR.",
      },
      {
        question:
          "How does the Social Listening Component work, and what kind of insights can it provide vaccination efforts?",
        status: false,
        answer: "The Social Listening component tracks and analyzes conversations on social media, news, and online discussions related to vaccination. It provides insights into public sentiments, concerns, and real time emerging trends in communication. These insights help in tailoring communication strategies to address vaccine hesitancy, misinformation, and public sentiment.",
      },
      {
        question:
          "What is BeSD Data, and hopw does it contribute to the ACSM Data Hub's Objectives?",
        status: false,
        answer: "BeSD Data, or Behavior and Social Drivers Data, includes information on community behaviors, socio-economic factors, and cultural influences affecting vaccination. This data helps tailor communication and social mobilization efforts to specific populations and contexts, increasing the effectiveness of vaccination initiatives",
      },
    ],
  },
  {
    title: "Getting started",
    active: false,
    desc: [
      {
        question: "How do I access the ACSM Data Hub dashboard?",
        status: false,
        answer: "Access to the dashboard can typically be obtained by requesting access through the dashboard administrator",
      },
      {
        question: "Is the data accessible to the public, or is it restricted to authorized users?",
        status: false,
        answer: "Data access is usually restricted to authorized users, ensuring that sensitive information is protected and used responsibly.",
      },
      {
        question: "Do I need to create an account to use the dashboard?",
        status: false,
        answer:
          "No, an account will be created for you by an administrator. Contact the admin on LIPt@sydani.org to be granted access to the dashboard",
      },
    ],
  },
  {
    title: "Data Collection and Sources",
    active: false,
    desc: [
      {
        question: "What types of data are stored in the ACSM Data Repository?",
        status: false,
        answer: "The repository contains data related to routine immunization, COVID-19, community behaviors, social determinants, and social media trends.",
      },
      {
        question: "Where does the data in the repository come from?",
        status: false,
        answer: "Data is sourced from various stakeholders, including government health departments, partners implementing demand generation activities, healthcare facilities, community surveys, and social media platforms.",
      },
      {
        question: "How often is the data updated in the repository?",
        status: false,
        answer:
          "Data in the repository is updated in real-time or at regular intervals, depending on the source. Frequent updates ensure the availability of the most current information.",
      },
    ],
  },
  {
    title: "Data Contribution",
    active: false,
    desc: [
      {
        question: "How can organizations or individuals contribute data or insights to the ACSM Data Repository?",
        status: false,
        answer: "Organizations or individuals can contribute data and insights through a designated data submission process. Collaborative partnerships are encouraged to enhance the data sources.",
      },
    ],
  },
  {
    title: "Benefits and Impact",
    active: false,
    desc: [
      {
        question: "What are the primary benefits of using data from the ACSM Data Repository for advocacy and communication efforts?",
        status: false,
        answer: "The data facilitates evidence-based decision-making, targeted communication strategies, improved resource allocation, and better vaccination program outcomes, ultimately contributing to public health outcomes especially for immunization.",
      },
    ],
  },
  {
    title: "Privacy and Security",
    active: false,
    desc: [
      {
        question: "What measures are in place to keep the data hub secure and protect sensitive information?",
        status: false,
        answer: "The ACSM Data Hub employs robust security measures to safeguard sensitive information. These measures include encryption of data in transit and at rest, role-based access control, regular security audits, and compliance with relevant data protection regulations. Access to the data is restricted to authorized users, and data anonymization and privacy protocols are enforced to protect individuals' personal information.",
      },
      {
        question: "Is the data in the dashboard anonymized, and how is data privacy maintained?",
        status: false,
        answer: "Yes, the data is anonymized, and data privacy is maintained through rigorous compliance with data protection regulations and ethical standards.",
      },
    ],
  },
  {
    title: "Troubleshooting",
    active: false,
    desc: [
      {
        question: "How can users provide feedback or report issues related to the data or platform functionality?",
        status: false,
        answer: "Users can provide feedback or report issues by using the designated 'contact details' feature within the ACSM Data Repository platform. This feature allows users to submit their comments, questions, or concerns directly. ",
      },
    ],
  },
  {
    title: "Support",
    active: false,
    desc: [
      {
        question: "What support or resources are available for users who need assistance navigating or analyzing the data?",
        status: false,
        answer: "Users can access documentation, tutorials, and a dedicated helpdesk for technical support and guidance on using the dashboard effectively.",
      },
      {
        question: "Are there any training materials or workshops available for users looking to enhance their data analysis skills?",
        status: false,
        answer: "Training materials and workshops may be provided periodically to help users make the most of the dashboard's features and data for decision making.",
      },
    ],
  },
  {
    title: "Updates and Maintenance",
    active: false,
    desc: [
      {
        question: "Are there plans for future enhancements or expansions of the ACSM Data Repository Dashboard?",
        status: false,
        answer: "Yes, the dashboard is subject to continuous improvement and may include new features, data sources, and functionalities to meet evolving needs. The goal is to integrate the hub into the DHIS2, the approved repository for administrative data.",
      },
    ],
  },
];
