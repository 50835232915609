import React, { useState } from "react";
import { setPeriod } from "../../../../../redux/actions/global.action";
import { useDispatch } from "react-redux";
import moment from "moment";

const usePeriod = ({ period, setSelectionRange }) => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(new Date('1/1/21'));
  const [toDate, setToDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  // function getYearsFrom2000() {
  //   const currentYear = new Date().getFullYear();
  //   const startYear = 2000;
  //   const years = [];

  //   for (let year = startYear; year <= currentYear; year++) {
  //     const value = year.toString();
  //     const label = year.toString();
  //     years.push({ value, label });
  //   }

  //   return years;
  // }
  // const getAllYears = getYearsFrom2000();

  const yearsOption = [
    {
      defaultValue: {
        value: fromDate,
        label: "From",
      },
      onChange: (data) => {
        let startDate;
        let endDate;
        startDate = new Date(data);
        endDate = new Date(period.endDate);
        if(startDate > endDate){
          endDate = new Date();
        }
        setSelectionRange([{ startDate, endDate, key: "selection" }]);
        dispatch(
          setPeriod({
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
          })
        );
        setShowCalendar(prev => false);
      },
    },
    {
      defaultValue: {
        value: toDate,
        label: "To",
      },
      onChange: (data) => {
        let startDate;
        let endDate;
        endDate = new Date(data);
        startDate = new Date(period.startDate);
        if(endDate < startDate){
          endDate = startDate
        }
        setSelectionRange([{ startDate, endDate, key: "selection" }]);
        dispatch(
          setPeriod({
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
          })
        );
        setShowCalendar(prev => false);
      },
    },
  ];
  return {
    yearsOption,
    showCalendar, setShowCalendar
  };
};

export default usePeriod;
