import React, { useState } from "react";
import "./contact.css";
import animationData from "../../lotties/lip.json";
import contactImg from "../../assets/images/LIP/Group 3989 copy.webp"
import globalService from "../../services/global.service";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.firstName.trim() === "" ||
      formData.lastName.trim() === "" ||
      formData.email.trim() === "" ||
      formData.phone.trim() === "" ||
      formData.message.trim() === ""
    ) {
      console.error("All fields must be filled out");
      return;
    }
    setSubmitting(true);
    globalService
      .submitContactForm({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      })
      .then((result) => {
        toast.success("Succcessfully submitted form");

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="contact-root-container">
          <div className="contact-header">Get in Touch</div>
        <div className="contact-inner-container">
          <div className="contact-header1 none">Love to hear from you</div>
          <div className="contact-content flex-row-3">
            <div className="contact-form flex-col-0">
              <form onSubmit={handleSubmit}>
                <div className="group-form flex-row-20">
                  <label>
                    First Name
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="contact-input-field"
                    />
                  </label>
                  <label>
                    Last Name
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="contact-input-field"
                    />
                  </label>
                </div>
                <div className="group-form flex-row-20">
                  <label>
                    Email
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="contact-input-field"
                    />
                  </label>
                  <label>
                    Phone Number
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="contact-input-field"
                    />
                  </label>
                </div>
                <div className="contact-textarea">
                  <label>
                    Message
                    <textarea
                      rows="6"
                      column="30"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="contact-input-field"
                    ></textarea>
                  </label>
                </div>
                <div className="contact-btn">
                  <button disabled={submitting} type="submit">
                    {submitting ? (
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                        visible={true}
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
              <div className="contact-text">
                <p>
                  Address: Plot 1422 Independence Avenue, Central Business
                  District, Abuja
                </p>
              </div>
            </div>
            <div className="contact-image">
              <img src={contactImg} alt="contact-display"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
