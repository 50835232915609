import Dashboard from "./pages/Dashboard";
import { HashRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import Layout from "./layout/Layout";
import Error404Page from "./pages/Error404Page";
import { createTheme, ThemeProvider } from "@mui/material";
import About from "./pages/About";
import ProgramAreas from "./pages/ProgramAreas";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Home from "./pages/Home/Home";
import { getUserLogin } from "./utils/helpers";
import FaqAndHelp from "./pages/FaqAndHelp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Resources from "./pages/Resources";
import Contact from "./pages/Contact";
import Blog from "./pages/Resources/components/Blog";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Avenir",
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastContainer position="top-center" autoClose={20000}/>
        <Router>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/" element={<Home />} />

            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/resources" element={<Resources />} />
              <Route exact path="/resource/:about" element={<Blog />} />
              <Route
                element={
                  <ProgramAreas>
                    <Outlet />
                  </ProgramAreas>
                }
              >
                <Route path="/vaccine_type" element={<ProgramAreas />} />
              </Route>
              <Route path="/faqs_support" element={<FaqAndHelp />} />
              <Route path="/contact" element={<Contact />} />
            </Route>

            <Route path="*" element={<Error404Page />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
