import moment from "moment";
import { program_areas } from "../../utils/constants";
import {
  SET_CONFIRM_MODAL,
  SET_COUNTRY_IN_FOCUS,
  SET_LG_IN_FOCUS,
  SET_POPUP_CHILDREN,
  SET_SELECTED_FACILITIES,
  SET_PRIMARY_INDICATORS,
  SET_SELECTED_LEVEL,
  SET_SELECTED_LGS,
  SET_SELECTED_PROGRAM_AREA,
  SET_SELECTED_STATES,
  SET_SHOW_POPUP,
  SET_STATE_IN_FOCUS,
  SET_SECONDARY_INDICATORS,
  SET_INDICATORS,
  SET_STATES,
  SET_LGAS_IN_STATE,
  SET_WARDS_IN_LGA,
  SET_CHART,
  SET_CHART_2,
  SET_PERIOD,
  SET_FIRST_COMPARABLE,
  SET_SECOND_COMPARABLE,
  SET_THIRD_COMPARABLE,
  SET_ACTIVE_STATES,
  SET_ACTIVE_LGAS_OF_FOCUSED_STATE,
  SET_GETTING_CHART_DATA,
  SET_SELECTED_CATEGORY,
  SET_CATEGORIES,
  SET_SELECTED_ELEMENTS,
  SET_DATA_TYPE,
  SET_DATA_SOURCE,
  SET_LEVEL_DATA,
  SET_DATA_SOURCES,
  SET_PROGRAM_AREAS,
  SET_SELECTED_BEHAVIOUR,
  SET_SELECTED_INDICATORS,
  SET_INDICATOR_COLORS,
} from "../actionTypes/global.type";

const defaultState = {
  showPopup: false,
  popupChildren: null,
  confirmModal: false,
  onModalDismiss: () => {},
  selectedProgramArea: null,
  selectedLevel: "",
  selectedStates: [],
  selectedLgas: [],
  selectedFacilities: [],
  indicators: [],
  primaryIndicators: [],
  secondaryIndicators: [],
  countryInFocus: "",
  stateInFocus: null,
  lgInFocus: "",
  states: [],
  lgasInState: [],
  wardsInLga: [],
  chart: [],
  chart2: [],
  period: {
    startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    endDate: moment().format("YYYY-MM-DD"),
  },
  dataType: "",
  firstComparable: {
    shouldShow: true,
    locationType: "summary",
    state: null,
    states: [],
    selectedStates: [],
    lga: null,
    lgas: [],
    selectedLgas: [],
    facilities: [],
    selectedFacilities: [],
    stateAction: {
      label: "View State Summary",
      value: "state_summary",
    },
    lgaAction: {
      label: "View LGA Summary",
      value: "lga_summary",
    },
    stateActions: [
      {
        label: "View State Summary",
        value: "state_summary",
      },
      {
        label: "Select LGA",
        value: "select_lga",
      },
    ],
    lgaActions: [
      {
        label: "View LGA Summary",
        value: "lga_summary",
      },
      {
        label: "View LGA Facilities",
        value: "lga_facilities",
      },
    ],
    showDate: true,
    selectedDate: null,
  },
  secondComparable: {
    shouldShow: false,
    locationType: "summary",
    state: null,
    states: [],
    selectedStates: [],
    lga: null,
    lgas: [],
    selectedLgas: [],
    facilities: [],
    selectedFacilities: [],
    stateAction: {
      label: "View State Summary",
      value: "state_summary",
    },
    lgaAction: {
      label: "View LGA Summary",
      value: "lga_summary",
    },
    stateActions: [
      {
        label: "View State Summary",
        value: "state_summary",
      },
      {
        label: "Select LGA",
        value: "select_lga",
      },
    ],
    lgaActions: [
      {
        label: "View LGA Summary",
        value: "lga_summary",
      },
      {
        label: "View LGA Facilities",
        value: "lga_facilities",
      },
    ],
    showDate: false,
    selectedDate: null,
  },
  thirdComparable: {
    shouldShow: false,
    locationType: "summary",
    state: null,
    states: [],
    selectedStates: [],
    lga: null,
    lgas: [],
    selectedLgas: [],
    facilities: [],
    selectedFacilities: [],
    stateAction: {
      label: "View State Summary",
      value: "state_summary",
    },
    lgaAction: {
      label: "View LGA Summary",
      value: "lga_summary",
    },
    stateActions: [
      {
        label: "View State Summary",
        value: "state_summary",
      },
      {
        label: "Select LGA",
        value: "select_lga",
      },
    ],
    lgaActions: [
      {
        label: "View LGA Summary",
        value: "lga_summary",
      },
      {
        label: "View LGA Facilities",
        value: "lga_facilities",
      },
    ],
    showDate: false,
    selectedDate: null,
  },
  activeStates: [],
  activeLgasOfFocusedState: [],
  gettingChartData: false,
  selectedCategory: null,
  categories: [],
  selectedElements: [],
  levelData: {
    generalAction: {
      label: "National Summary",
      value: "national_summary",
    },

    selectedSummary: {
      label: "National Summary",
      value: "national_summary",
    }, 

    states: [],
    selectedState: null,

    lgas: [],
    selectedLga: null,

    wards: [],
    selectedWard: null,
  },

  selectedBehaviour: {
    label: "Health Facility",
    value: "Health Facility",
  },
  dataSources: [],
  dataSource: null,
  programAreas: program_areas,
  selectedIndicators: [],
  indicatorColors: [
    "#8E30FF",
    "#0263FF",
    "#FF7723",
    "#152D6E",
    "#FFC60C",
    "#009900",
    "#0066FF",
    "#FF9900",
  ],
};

export default function globalReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SHOW_POPUP:
      return { ...state, showPopup: action.payload };
    case SET_POPUP_CHILDREN:
      return {
        ...state,
        popupChildren: action.payload,
        onModalDismiss: action.onDismiss,
      };
    case SET_CONFIRM_MODAL:
      return { ...state, confirmModal: action.payload };
    case SET_SELECTED_PROGRAM_AREA:
      return { ...state, selectedProgramArea: action.payload };
    case SET_SELECTED_LEVEL:
      return { ...state, selectedLevel: action.payload };
    case SET_SELECTED_STATES:
      return { ...state, selectedStates: action.payload };
    case SET_SELECTED_LGS:
      return { ...state, selectedLgas: action.payload };
    case SET_SELECTED_FACILITIES:
      return { ...state, selectedFacilities: action.payload };
    case SET_INDICATORS:
      return { ...state, indicators: action.payload };
    case SET_PRIMARY_INDICATORS:
      return { ...state, primaryIndicators: action.payload };
    case SET_SECONDARY_INDICATORS:
      return { ...state, secondaryIndicators: action.payload };
    case SET_COUNTRY_IN_FOCUS:
      return { ...state, countryInFocus: action.payload };
    case SET_STATE_IN_FOCUS:
      return { ...state, stateInFocus: action.payload };
    case SET_LG_IN_FOCUS:
      return { ...state, lgInFocus: action.payload };
    case SET_STATES:
      return { ...state, states: action.payload };
    case SET_LGAS_IN_STATE:
      return { ...state, lgasInState: action.payload };
    case SET_WARDS_IN_LGA:
      return { ...state, wardsInLga: action.payload };
    case SET_CHART:
      console.log(action.payload);
      return { ...state, chart: action.payload };
      case SET_CHART_2:
        console.log(action.payload);
        return { ...state, chart2: action.payload };
    case SET_PERIOD:
      return { ...state, period: action.payload };
    case SET_FIRST_COMPARABLE:
      return { ...state, firstComparable: action.payload };
    case SET_SECOND_COMPARABLE:
      return { ...state, secondComparable: action.payload };
    case SET_THIRD_COMPARABLE:
      return { ...state, thirdComparable: action.payload };
    case SET_ACTIVE_STATES:
      return { ...state, activeStates: action.payload };
    case SET_ACTIVE_LGAS_OF_FOCUSED_STATE:
      return { ...state, activeLgasOfFocusedState: action.payload };
    case SET_GETTING_CHART_DATA:
      return { ...state, gettingChartData: action.payload };
    case SET_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    case SET_CATEGORIES:
      return { ...state, categories: action.payload };
    case SET_SELECTED_ELEMENTS:
      return { ...state, selectedElements: action.payload };
    case SET_DATA_TYPE:
      return { ...state, dataType: action.payload };
    case SET_DATA_SOURCE:
      return { ...state, dataSource: action.payload };
    case SET_LEVEL_DATA:
      return { ...state, levelData: action.payload };
    case SET_DATA_SOURCES:
      return { ...state, dataSources: action.payload };
    case SET_PROGRAM_AREAS:
      return { ...state, programAreas: action.payload };
    case SET_SELECTED_BEHAVIOUR:
      return { ...state, selectedBehaviour: action.payload };
    case SET_SELECTED_INDICATORS:
      return { ...state, selectedIndicators: action.payload };
    case SET_INDICATOR_COLORS:
      return { ...state, indicatorColors: action.payload };
    default:
      return state;
  }
}
