import env from "../env";
import { request } from "./api";

const { API_URL } = env;

const URL = {
  getIndicators: `${API_URL}/acsm_datahub.data.indicators`,
  getStates: `${API_URL}/acsm_datahub.data.states`,
  getLgasInState: `${API_URL}/acsm_datahub.data.state_lgas`,
  getWardsInLga: `${API_URL}/acsm_datahub.data.lga_wards`,
  getAllStatesData: `${API_URL}/data.vac.statesData`,
  getSelectedStatesData: `${API_URL}/data.vac.stateData`,
  getAllLgasData: `${API_URL}/data.vac.lgasData`,
  getSelectedLgasData: `${API_URL}/data.vac.lgaData`,
  getAllFacilitiesDataInLga: `${API_URL}/data.vac.lgaFacilitiesData`,
  getSelectedFacilitiesData: `${API_URL}/data.vac.facilityData`,
  getActiveStates: `${API_URL}/acsm_datahub.data.states`,
  getActiveLgasInState: `${API_URL}/acsm_datahub.data.state_lgas`,
  getElementCategories: `${API_URL}/data.vac.elementsCategories`,
  getRiStateData: `${API_URL}/data.vac.riState`,
  getRiLgaData: `${API_URL}/data.vac.riLga`,
  getRiFacilityData: `${API_URL}/data.vac.riFacility`,
  getProgramAreas: `${API_URL}/acsm_datahub.data.program_area`,
  getDataSources: `${API_URL}/acsm_datahub.data.data_sources`,
  getFinalData: `${API_URL}/acsm_datahub.data.fetch`,
  getFinalData2: `${API_URL}/acsm_datahub.data.fetch_v2`,
  submitContactForm: `${API_URL}/acsm_datahub.data.contact`,
  getResourcesData: `${API_URL}/acsm_datahub.data.blog_resource`,
  getBehaviouralData: `${API_URL}/acsm_datahub.data.fetch_bahavioural`,
  getBehaviouralData2: `${API_URL}/acsm_datahub.data.fetch_bahavioural_v2`,
};

const getIndicators = (program_area, data_source) => {
  return request(URL.getIndicators, { program_area, data_source }, "GET");
};
const getStates = () => {
  return request(URL.getStates, {}, "GET");
};
const getLgasInState = (state) => {
  return request(URL.getLgasInState, { state }, "GET");
};

const getWardsInLga = (state, lga) => {
  return request(URL.getWardsInLga, { state, lga }, "GET");
};

const getAllStatesData = (indicatorId, dateTo, dateFrom) => {
  return request(
    URL.getAllStatesData,
    { indicatorId, dateTo, dateFrom },
    "GET"
  );
};

const getSelectedStatesData = (indicatorId, dateTo, dateFrom, stateId) => {
  return request(
    URL.getSelectedStatesData,
    { indicatorId, dateTo, dateFrom, stateId },
    "GET"
  );
};

const getAllLgasData = (indicatorId, dateTo, dateFrom, stateId) => {
  return request(
    URL.getAllLgasData,
    { indicatorId, dateTo, dateFrom, stateId },
    "GET"
  );
};

const getSelectedLgasData = (indicatorId, dateTo, dateFrom, lgaId) => {
  return request(
    URL.getSelectedLgasData,
    { indicatorId, dateTo, dateFrom, lgaId },
    "GET"
  );
};

const getAllFacilitiesDataInLga = (indicatorId, dateTo, dateFrom, lgaId) => {
  return request(
    URL.getAllFacilitiesDataInLga,
    { indicatorId, dateTo, dateFrom, lgaId },
    "GET"
  );
};
const getSelectedFacilitiesData = (
  indicatorId,
  dateTo,
  dateFrom,
  facilityId
) => {
  return request(
    URL.getSelectedFacilitiesData,
    { indicatorId, dateTo, dateFrom, facilityId },
    "GET"
  );
};

const getActiveStates = () => {
  return request(URL.getActiveStates, {}, "GET");
};

const getActiveLgasInState = (state) => {
  return request(URL.getActiveLgasInState, { state }, "GET");
};

const getElementCategories = (com) => {
  return request(URL.getElementCategories, { com }, "GET");
};

const getRiStateData = (com, ctype, stateId, months) => {
  return request(URL.getRiStateData, { com, ctype, stateId, months }, "GET");
};

const getAllRiStateData = (com, ctype, months) => {
  return request(URL.getRiStateData, { com, ctype, months }, "GET");
};

const getRiLgaData = (com, ctype, stateId, lgaId, months) => {
  return request(
    URL.getRiLgaData,
    { com, ctype, stateId, lgaId, months },
    "GET"
  );
};

const getAllRiLgaDataInState = (com, ctype, stateId, months) => {
  return request(URL.getRiLgaData, { com, ctype, stateId, months }, "GET");
};

const getRiFacilityData = (com, ctype, stateId, lgaId, facilityId, months) => {
  return request(
    URL.getRiFacilityData,
    { com, ctype, stateId, lgaId, facilityId, months },
    "GET"
  );
};

const getProgramAreas = () => {
  return request(URL.getProgramAreas, {}, "GET");
};
const getDataSources = () => {
  return request(URL.getDataSources, {}, "GET");
};

const getFinalData = (data) => {
  return request(URL.getFinalData, data, "GET");
};

const getFinalData2 = (data) => {
  return request(URL.getFinalData2, data, "GET");
};

const submitContactForm = (data) => {
  return request(URL.submitContactForm, data, "POST");
};

const getResourcesData = () => {
  return request(URL.getResourcesData, {}, "GET");
};

const getFinalBehaviouralData = (data) => {
  return request(URL.getBehaviouralData, data, "GET");
};

const getFinalBehaviouralData2 = (data) => {
  return request(URL.getBehaviouralData2, data, "GET");
};

const globalService = {
  getIndicators,
  getStates,
  getLgasInState,
  getWardsInLga,
  getAllStatesData,
  getSelectedStatesData,
  getAllLgasData,
  getSelectedLgasData,
  getSelectedFacilitiesData,
  getActiveStates,
  getActiveLgasInState,
  getAllFacilitiesDataInLga,
  getElementCategories,
  getRiStateData,
  getRiLgaData,
  getRiFacilityData,
  getAllRiLgaDataInState,
  getAllRiStateData,
  getProgramAreas,
  getDataSources,
  getFinalData,
  getFinalData2,
  submitContactForm,
  getResourcesData,
  getFinalBehaviouralData,
  getFinalBehaviouralData2,
};
export default globalService;
