import { Typography } from "@mui/material";
import React from "react";
import "./About.css";
import whoWeAreImg from "../../assets/images/LIP/people-office-during-work-day (1) copy.webp";
import aboutAcmImg from "../../assets/images/LIP/Group 4006 copy.webp";
import whyAcmImg from "../../assets/images/LIP/Group 4005 copy.webp";
const About = () => {
  return (
    <div className={"about-root-container"}>
      <div className="about-first-container">
        <div className="about-first-left">
          <div style={{ marginTop: 50 }}>
            <h4 className={"title"}>About us</h4>
          </div>
          <div>
            <p className={"about-paragraphs"}>
              {" "}
              ACSM Data Hub is a repository of demand generation data that helps
              sustain vaccine advocacy, communication, and social mobilization
              efforts. The ACSM Data Hub empowers stakeholders to access,
              analyze, and visualize real-time data on routine immunization and
              COVID-19 vaccination.
            </p>
            <p className={"about-paragraphs"}>
              {" "}
              The ACSM Data Hub will be piloted in sixstates in the country
              namely, Bayelsa,Benue, Ekiti, Enugu, Kano, and Niger.
            </p>
          </div>
        </div>
        <div className="about-first-right">
          <img src={aboutAcmImg} className="about-acm-img" alt="about acm" />
        </div>
      </div>
      <div className="about-second-container">
        <div className="about-second-left">
          <img src={whyAcmImg} className="why-acm-img" alt="why acm" />
        </div>
        <div className="about-second-right">
          <div style={{ marginTop: 50 }}>
            <h4 className={"title"}>Why ACSM Data Matters</h4>
          </div>
          <div>
            <p className={"about-paragraphs"}>
              {" "}
              Understanding public perceptions and coordinating data generated
              by ACSM activities are essential components of effective
              vaccination strategies. Our project aims to:
            </p>
            <p className={"about-paragraphs"}>
              {" "}
              Coordinate ACSM data from various sources which streamlines data
              management and avoids data fragmentation
            </p>
            <p className={"about-paragraphs"}>
              {" "}
              Support informed decisions in public health initiatives by
              providing timely and relevant information
            </p>
          </div>
        </div>
      </div>
      {/* <div className="about-third-container">
        <div>
          <div style={{ marginTop: 50 }}>
            <h4 className={"title"}>Our Vision & Mission</h4>
            <p style={{ fontSize: 16 }}>
              We strengthen insights to Vaccination Data
            </p>
          </div>
          <div>
            <p className={"about-paragraphs"} style={{ paddingRight: 100 }}>
              The Listening for Impact (LIP) ACSM Data Hub, is a pioneering
              initiative by Sydani Group with funding from the United States
              Centre for Disease Control and Prevention (US-CDC) to
              revolutionize ACSM data management in Nigeria
            </p>
            <p className={"about-paragraphs"} style={{ paddingRight: 100 }}>
              Our mission is to create a centralized repository and strategic
              resource for guiding the planning, execution, and assessment of
              ACSM activities throughout the country
            </p>
          </div>
        </div>
        <div className="third-inner-container">
          <div style={{ width: "50%" }}>
            <h4 className={"title"} style={{ marginBottom: 20 }}>
              Who We Are
            </h4>
            <p className={"about-paragraphs"}>
              Sydani group is a management consulting firm dedicated to
              providing client-focused cutting-edge expert solutions to complex
              problems. The Listening for Impact Project (LIP) in Sydani, funded
              by the US-CDC, aims to address critical information gaps and
              enhance the management of ACSM data related to vaccination.
            </p>
          </div>
          <div className="bottom-img-container">
            <img
              className="bottom-img"
              src={whoWeAreImg}
              alt="we are dedicated"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default About;
