import React, { useState } from "react";
import { Link } from "react-router-dom";
import PopupModal from "../../components/PopupModal";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import "./Login.css";
import sydani from "../../assets/images/LIP/sydani-logo.svg";
import nphe from "../../assets/images/LIP/nphe.svg";
import AcsmLogo from "../../assets/images/LIP/Group 4008.png";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "../../services/auth.service";
import { RotatingLines } from "react-loader-spinner";
import { setMyData } from "../../redux/actions/user.action";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLogDetails, setShowLogDetails] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const togglePasswordShow = () => {
    setShowLogDetails((prev) => !prev);
  };

  const handleLogin = () => {
    if (!email.trim().length) {
      return toast.error("Invalid email");
    }

    if (!password.trim().length) {
      return toast.error("Invalid password");
    }
    setLoggingIn(true);
    authService
      .login(email, password)
      .then(({ message, home_page, full_name, roles }) => {
        if (message.success) {
          console.log(message?.roles); //['NationalAdmin']
          toast.success("Successfully logged in");
          setToken(`${message.api_key}:${message.api_secret}`);
          setUser({ ...message, home_page, full_name });
          dispatch(setMyData({ ...message, home_page, full_name }));
          navigate("/dashboard");
        } else {
          toast.error(message.message);
        }
      })
      .catch(({ error }) => {
        toast.error(error);
      })
      .finally(() => setLoggingIn(false));
  };
  return (
    <div className="login-root-container">
      <div className="Login_Wrapper">
        <div className="Login_Card_Background">
          <div className="Login_Card">
            <div className="Login_Header">
              <span className="Login_Header_Logo">
                <img
                  className="header-logo-height"
                  src={AcsmLogo}
                  alt="acsm_logo"
                />
              </span>
              <span className="Login_Header_FormTitle">
                <Link id="login_header_Home" to="/">
                  <ArrowCircleLeftIcon className="header-icon" />
                  <span>Back to Home</span>
                </Link>
              </span>
            </div>
            <div className="Login_Form">
              <div className="Login_TextWrapper">
                <label className="Login_Form_Label" htmlFor="">
                  Email/ Username
                </label>
                <input
                  value={email}
                  className="Login_Form_Input"
                  type="text"
                  name=""
                  id=""
                  onChange={({ target }) => setEmail(target.value)}
                />
              </div>

              <div className="Login_TextWrapper">
                <label className="Login_Form_Label" htmlFor="">
                  Password
                </label>
                <div className="login-content">
                  <input
                    value={password}
                    className="Login_Form_Input"
                    type={showLogDetails ? "text" : "password"}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                  <button className="showLogin" onClick={togglePasswordShow}>
                    {showLogDetails ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
              <div className="Login_Form_ForgottenPassword">
                <Link to="/forgot-password">Forgot Password ?</Link>
              </div>
              <button
                // onClick={() => {
                //   setUserLogin();
                //   navigate("/");
                // }}
                disabled={loggingIn}
                onClick={handleLogin}
                className="Login_LoginBtn"
              >
                {loggingIn ? (
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </div>
          <div className="Login_Bottom">
            <div className="Login_Bottom_Powered_Container">
              <div className="Login_Bottom_Powered">
                <span>Powered By</span>
                <span className="Login_Bottom_Logo">
                  <img
                    className="logo-height"
                    id="logo"
                    src={sydani}
                    alt="sydani-logo"
                  />
                </span>
              </div>
            </div>
            <div className="Login_Bottom_Partner_Container">
              <div className="Login_Bottom_Partner">
                <span>In partnership with</span>
                <span className="Login_Bottom_Logo">
                  <img className="logo-height" src={nphe} alt="nphe-logo" />
                </span>
              </div>
            </div>
          </div>

          <span className="Bottom-span">
            © 2023 Copyright LIP. All Rights Reserved
          </span>
        </div>
        <PopupModal />
      </div>
    </div>
  );
}
