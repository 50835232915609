import { SET_MY_DATA } from "../actionTypes/users.type";

const defaultState = {
  myData: null,
};

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_MY_DATA:
      return { ...state, myData: action.payload };
    default:
      return state;
  }
}
