import React from "react";
import CardUrl from "./CardUrl";
import useCardBottom from "./Hook/useCardBottom";

const CardBottom = ({ category, getDataByCategory }) => {
  const { categoryBlogs, handleClickBlog } = useCardBottom({
    category,
    getDataByCategory,
  });
  return (
    <>
      <div className="customer-bottom">
        {categoryBlogs?.map((data) => (
          <>
            <div key={data.meta_title} className="bottom-content" onClick={() => handleClickBlog(data)}>
                <CardUrl url={data?.meta_image}/>
              <p className="bottom-p-1">{data?.meta_title}</p>
              <p className="bottom-p-2">
                {data?.meta_description}
              </p>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default CardBottom;
