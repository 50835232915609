import PopupModal from "./PopupModal";
import { connect } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../redux/actions/global.action";
const mapState = ({ global }) => ({
  showPopup: global.showPopup,
  popupChildren: global.popupChildren,
  confirmModal: global.confirmModal,
  onModalDismiss: global.onModalDismiss,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (data) => dispatch(togglePopup(data)),
  setPopupChildren: (data) => dispatch(setPopupChildren(data)),
});
export default connect(mapState, mapDispatchToProps)(PopupModal);
