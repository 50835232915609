import React from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import BehaviourIcon from "../../assets/images/LIP/behavioural.png";
import RepositoryIcon from "../../assets/images/LIP/acsm.png";
import { useDispatch } from "react-redux";
import {
  setDataType,
  setLevelData,
  setSelectedBehaviour,
  setSelectedProgramArea,
} from "../../redux/actions/global.action";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetDatas = () => {
    dispatch(
      setLevelData({
        generalAction: {
          label: "National Summary",
          value: "national_summary",
        },
        states: [],
        selectedState: null,

        lgas: [],
        selectedLga: null,

        wards: [],
        selectedWard: null,
      })
    );
    dispatch(
      setSelectedBehaviour({
        label: "Health Facility",
        value: "Health Facility",
      })
    );
    dispatch(setSelectedProgramArea(null));
  };
  const dashboardMenus = [
    {
      title: "ACSM Repository",
      description:
        "The ACSM Data Hub is a repository of real-time information.",
      icon: <img src={RepositoryIcon} alt="repository-icon" className="dashboard-card-img-icon" />,
      onClick: () => {
        navigate("/vaccine_type");
        dispatch(setDataType("REPOSITORY"));
        resetDatas();
      },
    },
    {
      title: "Behavioural Performance Data",
      description:
        "The ACSM Data Hub is a repository of real-time information.",
      icon: <img src={BehaviourIcon} alt="behavioural-icon" className="dashboard-card-img-icon" />,
      onClick: () => {
        navigate("/vaccine_type");
        dispatch(setDataType("PERFORMANCE_DATA"));
        resetDatas();
      },
    },
  ];

  return (
    <div className={"dashboard-root"}>
      <div className="dashboard-bg-opacity" />
      <div className="dashboard-inner">
        <div className="dashboard-header">Welcome to the ACSM data hub</div>
        <div className="dashboard-main">
          <div className="dashboard-cards-wrapper">
            {dashboardMenus.map((item, i) => (
              <div className="dashboard-card" onClick={() => item?.onClick()}>
                <div className="dashboard-card-img-container">{item?.icon}</div>
                <div className="dashboard-card-right">
                  <div className="dashboard-title">{item.title}</div>
                  <div className="dashboard-description">
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="right-side">
            <div className="right-title-text">
              Get real-time hub for diverse vaccine-related data sources
            </div>
            <div className="right-description-text">
              The ACSM (Advocacy Communication and Social Mobilization) Data Hub
              is a repository of real-time information, encompassing diverse
              data sources related to vaccination efforts
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
