import { useEffect, useState } from "react";
import globalService from "../../../services/global.service";

const useResources = () => {
  const [resourceData, setResourceData] = useState(null);
  const [categoryData, setCategoryData] = useState("")
  const [resourceFetch, setResourceFetch] = useState(false);
  const [resourceLink, setResourceLink] = useState("Latest Articles");
  let filteredCategoryData;
  const getResourceData = () => {
    setResourceFetch((prev) => true);
    globalService
      .getResourcesData()
      .then((data) => {
        setResourceData((prev) => data?.message);
      })
      .catch((err) => {
        console.log(err);
        setResourceFetch((prev) => false);
      })
      .finally(() => {
        setResourceFetch((prev) => false);
      });
  };
  const getCategory = () => {
    if (resourceData) {
      const category = resourceData?.map(
        (data) => data.category.charAt(0).toUpperCase() + data.category.slice(1)
      );
      const uniqueCategories = [...new Set(category)];
      const newlyFetchCategory = ["Latest Articles", ...uniqueCategories];
      const filteredCategory = newlyFetchCategory.map((data) => {
        return {
          name: data,
          active: data === newlyFetchCategory[0] ? true : false,
        };
      });
      filteredCategoryData = filteredCategory
      return filteredCategory;
    }
    return null;
  };
  const getDataByCategory = (category) => {
    return resourceData?.filter((item) => item.category === category);
  };

  const changeActiveLink = (data) => {
    // setcategoryData((prev) =>
    //   prev.map((categoryData) => {
    //     console.log(categoryData);
    //     if (categoryData.name === data) {
    //       categoryData.active = true;
    //     } else {
    //       categoryData.active = false;
    //     }
    //   })
    // );
    setResourceLink((prev) => data);
  };

  useEffect(() => {
    getResourceData();
    return () => {
      setResourceData((prev) => null);
    };
  }, []);
  return {
    resourceData,
    resourceFetch,
    getDataByCategory,
    resourceLink,
    changeActiveLink,
    getCategory,
  };
};

export default useResources;
